/* eslint-disable */
const en = {
  "403": "403 - Forbidden. You are not logged in, or don't have the permissions to do this.",
  "404": "404 - Resource not found. If it should exist, check that you are logged in.",
  "500": "500 - Internal Server Error",
  "BETA": "BETA",
  "about": "About",
  "aboutToPlay": "You are about to start playing a game!",
  "acceptDraw": "Accept draw?",
  "accountDoesNotExist": "That account does not exist.",
  "accountHasNoEmail": "There is no email address associated with this account.",
  "accountList": "Account list",
  "accountSettings": "Account settings",
  "add": "Add",
  "addATeam": "Add a team",
  "addClub": "Add a club",
  "addClubId": "Add Club ID",
  "addMember": "Add member",
  "addOtherAccount": "Add other account",
  "addPairing": "Add Pairing",
  "addParticipantsWithAccount": "Add participants with account",
  "addParticipantsWithoutAccount": "Add participants without account",
  "addPlayerId": "Add Player ID",
  "addRound": "Add Round",
  "age": "Age",
  "allowSelfJoining": "Allow self-joining",
  "alreadyHaveAccount": "Already have a SpillSjakk account?",
  "alreadyMemberOfTeam": "That account is alredy a member of this team.",
  "areYouAtSpillsjakkDiscord": "Are you at SpillSjakk Discord?",
  "averageAge": "Average age",
  "averageOpponentRating": "Average opponent rating",
  "backToTournament": "Return to tournament",
  "banner_picture": "Banner Photo Url",
  "birthDate": "Birth date",
  "black": "Black",
  "blackWins": "Black wins, 0-1.",
  "blackWon": "black won",
  "board": "Board",
  "boardCards": "Board cards",
  "browse": "Browse",
  "browseClubs": "Browse Clubs",
  "browseOrgs": "Browse Organizations",
  "browseOrgsAndClubs": "Browse Organizations and Clubs",
  "buchholz": "Buccholz",
  "buchholzCut1": "Buchholz Cut 1",
  "buchholzCut2": "Buchholz Cut 2",
  "buildAndInvite": "Build and invite players",
  "buildTournament": "Build a tournament",
  "bulkAccountCreation": "Bulk Account Creation",
  "byRating": "By rating",
  "carousel1": "Tournament software with integrated online playing platform",
  "carousel1Desc": "Easy organization of individual tournaments, team tournaments, league games and hybrid tournaments online and/or across the board. Supporting Swiss, Round Robin, Knockout and Manual Pairing.",
  "carousel2": "For multiple purposes",
  "carousel2Desc": "A flexible and user-friendly tool adapted to projects and activities organised from the federation level to the smallest club events",
  "carousel3": "SpillSjakk belongs to the chess players",
  "carousel3Desc": "Through the national federations, it's the club players who own SpillSjakk. This means that SpillSjakk will always put the needs of the active chess players first",
  "carousel4": "Have your say!",
  "carousel4Desc": "Join SpillSjakk's Discord server for the latest updates from the developer team. Participate in test tournaments, contribute with your feedback and suggestions for SpillSjakk to be the best possible!",
  "changeEmail": "Change email",
  "changeNextPairingDateTime": "Change the time of the next pairing:",
  "changePassword": "Change password",
  "clickToCopy": "Click to Copy",
  "clubCount": "Club count",
  "clubInvitations": "Club Invitations",
  "clubYouHaveBeenInvited": "You’ve been invited to add your Club by $name on SpillSjakk. It’s quick, easy, and lets you invite your Club’s players to join in large team tournaments. Additionally, your Club can use SpillSjakk’s online and offline pairing tools for internal tournaments.",
  "clubs": "Clubs",
  "confirmTournamentDeletion": "Do you delete the tournament?",
  "confirmTournamentWithVisibleNames": "Users's names will be visible on the tournament detail page, if you do not want the users', please check the ",
  "congrats": "Congratulations",
  "contact": "Contact",
  "continue": "Continue",
  "continueWithoutTemplate": "Continue Without Selecting Template",
  "copied": "Copied",
  "country": "Country",
  "create": "Create",
  "createATeam": "Create a team",
  "createAccounts": "Create accounts",
  "createAccount": "Create account",
  "createYourAccount": "Create Your Spillsjakk Account",
  "createYourClub": "Create Your Spillsjakk Club",
  "currentPassword": "Current password",
  "crossTable": "Cross Table",
  "custom": "Custom",
  "dateTime": "Date/time",
  "days": "days",
  "defaultGameLocation": "Default game location",
  "deletePairing": "Delete Pairing",
  "deleteRound": "Delete Round",
  "deleteTournament": "Delete Tournament",
  "description": "Description",
  "downloadAsCsv": "Download as CSV",
  "draw": "the game was a draw",
  "drawOfferPending": "Draw offer pending...",
  "drawPoints": "Points for a draw",
  "editTournament": "Edit the tournament details",
  "email": "Email",
  "emailChanged": "Your email address has been changed.",
  "endDate": "End date",
  "expandAll": "Expand All",
  "featuresWeOffer": "FEATURES WE OFFER",
  "federation": "Federation",
  "fideFederation": "FIDE federation",
  "fideprofil": "FIDE Profil",
  "fideInfoText": "Add your FIDE ID if you have one, if not press next",
  "fideNumber": "FIDE number",
  "fideRated": "This is a FIDE rated tournament.",
  "fideRating": "FIDE rating",
  "fillDateAndTime": "Fill in the date and time of the round",
  "findTournament": "Find a tournament",
  "findTournaments": "Find tournaments",
  "findoutFide": "How do I find out if I have a FIDE ID number?",
  "finished": "Finished",
  "firstName": "First name",
  "firstPairingDateTime": "First online pairing date and time",
  "flaggingTime": "Flagging time!",
  "forgotPassword": "Forgot password?",
  "fullName": "Full name",
  "game": "Game",
  "gameLocationCanBeChanged": "Game location can be changed for each pairing when the tournament is running.",
  "gameScore": "Game score",
  "games": "Games",
  "gender": "Gender",
  "generate-next-round": "Generate Next Round",
  "getInTouch": "GET IN TOUCH",
  "goToLobby": "Go to the lobby",
  "goodGame": "Good game",
  "greatEndgame": "Great endgame technique",
  "hello": "Hello",
  "home": "Home",
  "hours": "hours",
  "iDidntNeedThatPiece": "I didn't need that piece anyway",
  "iLikeYourOpening": "I like your opening",
  "iNeedtoRethink": "I need to rethink my approach to chess",
  "id": "ID",
  "idAlreadyTaken": "This ID is already taken.",
  "ifNoOnlineGames": "If pairing is OTB, enter any date and time",
  "inbox": "Inbox",
  "incorrectPassword": "Incorrect password",
  "individual": "Individual",
  "invalidCredentials": "Invalid username or password.",
  "invalidPasswordRecoveryCode": "The given password recovery code is invalid.",
  "invite": "Invite",
  "itsADraw": "It's a draw, 1/2-1/2.",
  "join": "Join",
  "joinFor": "Join this tournament for",
  "joinOrganization": "Join organization",
  "joinTournament": "Join Tournament",
  "knockout": "Knockout",
  "lastName": "Last name",
  "leave": "Leave",
  "leaveTournament": "Leave Tournament",
  "letsPlayAgain": "Let's play again sometime",
  "liveAndUpcomingTournaments": "LIVE & UPCOMING TOURNAMENTS",
  "lobby": "Lobby",
  "localTime": "local time",
  "login": "Log in",
  "loginAndTryAgain": "Please login and try again",
  "loginFirst": "Please login first",
  "logout": "Log out",
  "lossPoints": "Points for a loss",
  "manage": "Manage",
  "manageLeague": "Manage League",
  "manageClub": "Manage club",
  "manageClub_aboutClub": "About the Club: ",
  "manageClub_clubsManager": "Club's Manager: ",
  "manageClub_aboutClubDesc": "(this is publicly viewable and is a chance to tell the public about your club)",
  "manageClub_accounts": "Accounts within Club",
  "manageClub_addNewTeam": "new team",
  "manageClub_address": "Address: ",
  "manageClub_addressDesc": "(your postal address or primary physical location will be shared on your profile page)",
  "manageClub_bannerPhoto": "Banner Photo Url: ",
  "manageClub_bannerPhotoDesc": "(this is a larger picture appearing on your profile page - do you have sponsors or a photo you’d like to publicly share? Just copy a URL ending in .jpg or .png below)",
  "manageClub_clubsId": "Club's URL (SpillSjakk ID): ",
  "manageClub_clubsIdDesc": "(this field will appear at the end of web URLs on SpillSjakk, e.g., spillsjakk.no/club/view/CUSTOMURL)",
  "manageClub_clubsName": "Club's name: ",
  "manageClub_clubsNameDesc": "(this name will be publicly visible)",
  "manageClub_email": "Email: ",
  "manageClub_emailDesc": "(a public email address if people have enquiries about your Club)",
  "manageClub_profilePhoto": "Profile Photo Url: ",
  "manageClub_profilePhotoDesc": "(this should be your brand or logo - it will appear on tournaments you make via SpillSjakk, and on your profile page. Just copy a URL ending in .jpg or .png below)",
  "manageClub_teamCaptainPackage": "Team Captain",
  "manageClub_teamCaptainPackageDesc": "can send messages to the members and move users from a club into their team",
  "manageClub_teamsInClub": "Teams within Club",
  "manageClub_totalPlayerCount": "Total Player Count:",
  "manageClub_totalTeamCount": "Total Team Count:",
  "manageClub_website": "Website & Social media: ",
  "manageClub_websiteDesc": "(your Club’s website, twitter, facebook, etc, will be shared on your profile page)",
  "manageOrg_aboutOrg": "About the Organization:",
  "manageOrg_address": "Address: ",
  "manageOrg_addressDesc": "(your postal address or primary physical location will be shared on your profile page)",
  "manageOrg_allPowersPackage": "All powers package:",
  "manageOrg_allPowersPackageDesc": "(this user will be able to do everything this Organization can do, in its name)",
  "manageOrg_arbiterPowersPackage": "Arbiter powers package:",
  "manageOrg_arbiterPowersPackageDesc": "(this user will have all arbiter powers in this Organization’s events, and can create events)",
  "manageOrg_bannerPhoto": "Banner Photo Url: ",
  "manageOrg_bannerPhotoDesc": "(this is a larger picture appearing on your profile page - do you have sponsors or a photo you’d like to publicly share? Just copy a URL ending in .jpg or .png below)",
  "manageOrg_editorPowersPackage": "Editor powers package:",
  "manageOrg_editorPowersPackageDesc": "(this user will have all author and editor powers, in all text fields the Organization controls)",
  "manageOrg_email": "Email: ",
  "manageOrg_emailDesc": "(a public email address if people have enquiries about your Organization)",
  "manageOrg_orgsAccounts": "Accounts within Organization:",
  "manageOrg_orgsClubs": "Clubs within Organization:",
  "manageOrg_orgsDesc": "About the Organization: ",
  "manageOrg_orgsDescDesc": "(this is publicly viewable and is a chance to tell the public about your organization)",
  "manageOrg_orgsId": "Organization’s URL (SpillSjakk ID): ",
  "manageOrg_orgsIdDesc": "(this field will appear at the end of web URLs on SpillSjakk, e.g., spillsjakk.no/organization/view/CUSTOMURL)",
  "manageOrg_orgsName": "Organization’s name: ",
  "manageOrg_orgsNameDesc": "(this name will be publicly visible)",
  "manageOrg_profilePhoto": "Profile Photo Url: ",
  "manageOrg_profilePhotoDesc": "(this should be your brand or logo - it will appear on tournaments you make via SpillSjakk, and on your profile page. Just copy a URL ending in .jpg or .png below)",
  "manageOrg_sharePower": "Share powers with an account:",
  "manageOrg_totalClubCount": "Total Club Count:",
  "manageOrg_totalPlayerCount": "Total Player Count:",
  "manageOrg_website": "Website & Social media: ",
  "manageOrg_websiteDesc": "(your Organization’s website, twitter, facebook, etc, will be shared on your profile page)",
  "manageOrganization": "Manage organization",
  "manageParticipants": "Manage participants",
  "manageRoundsAndPairings": "Manage Rounds And Pairings",
  "manageTeamPlayers": "Manage team players",
  "manageTournament": "Manage Tournament",
  "manualPairing": "Manual Pairing",
  "matchScore": "Match score",
  "medianBuchholz": "Median Buchholz",
  "medianBuchholz2": "Median Buchholz 2",
  "members": "Members",
  "membersPerTeam": "Members per team",
  "messageJustToTeamMembers": "Message just to team members",
  "messageMembers": "Message members",
  "mfRatio": "M/F ratio",
  "minutes": "minutes",
  "mouseSlip": "Argh! Mouseslip",
  "myTeams": "My teams",
  "myTournamentCalendar": "My Tournament Calendar",
  "name": "Name",
  "nameOfThePlayer": "name or FIDE number",
  "namePrivacyInfo": "Your real name is normally hidden publicly, except for certain tournaments. You will be notified if a tournament will display your real name. Otherwise, only the admins of Clubs and Organisations you join will see this. For more information, please read our $privacypolicy. ",
  "navbarAccountCreation": "ACCOUNT CREATION",
  "navbarInfo": "INFO",
  "navbarLogin": "LOGIN",
  "navbarOrgs": "ORGANIZATIONS & CLUBS",
  "navbarTournaments": "TOURNAMENTS",
  "newPassword": "New password",
  "newPasswordAgain": "New password (again)",
  "next": "Next",
  "nextGame": "Next Game",
  "nextGameIn": "Your next game starts in:",
  "nextOnlinePairingWillBeAt": "The next online pairing will be at",
  "nextRoundText": "The next round starts in: ",
  "niceTactic": "Nice tactic",
  "no": "No",
  "noUpcomingGames": "There are no upcoming games for you at the moment.",
  "notDisplayedPublicly": "This is not displayed publicly",
  "notFound": "Not Found",
  "notParticipating": "Not participating",
  "numberOfRounds": "Number of Rounds",
  "off": "off",
  "offerDraw": "Offer draw",
  "on": "on",
  "oneClickJoin": "One-click join",
  "ongoing": "Ongoing",
  "ongoingTournaments": "Ongoing tournaments",
  "online": "Online",
  "onlinePairingInterval": "Interval between online games",
  "onlyShowScoresOfTop": "Only show scores of individuals and teams in the top",
  "opponent": "Opponent",
  "organiser": "organiser",
  "organizationInvitations": "Organization Invitations",
  "organizationStatistics": "Organization statistics",
  "organizations": "Organizations",
  "otb": "Over the board",
  "otherClubMembers": "Other club members",
  "pageNotFound": "This page has not been found.",
  "pairingPrintouts": "Pairing printouts",
  "pairings": "Pairings",
  "participant": "Participant",
  "participants": "Participants",
  "participating": "Participating",
  "password": "Password",
  "passwordChanged": "Your password has been changed.",
  "passwordRecoveryEmailSent": "We sent you an email with a link to recover your password.",
  "passwords": "Passwords",
  "passwordsAreNotEqual": "The two passwords are not equal.",
  "permissions": "Permissions",
  "play": "Play",
  "playGame": "Play game",
  "player": "Player",
  "playerCount": "Player count",
  "players": "Players",
  "playersPerTeam": "players per team",
  "pleaseSelect": "Please Select",
  "printoutPage": "Printout Page",
  "privacyPolicy": "privacy policy",
  "privateTournament": "This tournament is private.",
  "productDescription": "Fast, simple, and reliable chess tournament administration and pairings.",
  "productName": "SpillSjakk",
  "profilePicturePlaceholder": "Please fill in the url of the image",
  "profile_picture": "Profile Photo Url",
  "provisionalFideRating": "Provisional FIDE rating",
  "pseudonym": "Pseudonym",
  "publiclyViewable": "Publicly viewable",
  "random": "Random",
  "rating": "Rating",
  "rawPgn": "Raw PGN",
  "recover": "Recover",
  "recoverAccount": "Recover account",
  "region": "Region",
  "remove": "Remove",
  "resign": "Resign",
  "result": "Result",
  "resultPrintouts": "Result printouts",
  "reviewGame": "Review Game",
  "round": "Round",
  "roundNb": "Number of rounds",
  "roundRobin": "Round Robin",
  "rounds": "rounds",
  "saveUpdates": "Save Updates",
  "savedtournament": "Saved Tournament Templates",
  "score": "Score",
  "searchByFideId": "Search By FIDE ID",
  "searchEllipsis": "Search...",
  "searchTournaments": "Search tournaments",
  "seconds": "seconds",
  "seed": "Seed",
  "seeding": "Seeding",
  "send": "Send",
  "setup": "Setup",
  "setupRound": "Setup Round",
  "sex": "Sex",
  "showOnlyUsernames": "Show only username's of players on tournament detail page",
  "spectate": "Spectate",
  "standings": "Standings",
  "start": "Start",
  "startDate": "Start Date",
  "startTyping": "start typing or select",
  "startsIn": "Starts in ",
  "statistics": "Statistics",
  "submit": "Submit",
  "suggestedAccounts": "Suggested accounts",
  "suggestedOrganizations": "Suggested organizations",
  "swissDutch": "Swiss (Dutch)",
  "team": "Team",
  "teamKnockout": "Team Knockout",
  "teamKonrad": "Team Konrad",
  "teamManager": "Team manager",
  "teamMonrad": "Team SkoleSjakken",
  "teamOlympiad": "Team Olympiad",
  "teamRoundRobin": "Team Round Robin",
  "teamSwissDutch": "Team Swiss (Dutch)",
  "teams": "Teams",
  "thankYouForGame": "Thank you for the game",
  "theUrlForThisTeam": "The URL for this team will be /team/view/<id>",
  "theUrlForThisTournament": "The URL for this tournament will be /tournament/view/<id>",
  "thisIsTheOnlyTimeYouSeeThesePasswords": "See usernames and passwords for newly created accounts here. This is the only time you see these passwords!",
  "tiebreaker": "Tiebreaker",
  "timeControl": "Time control",
  "title": "Title",
  "toMakeFirstMove": "to make the first move",
  "totalClubCount": "Total club count",
  "totalPlayerCount": "Total player count",
  "tournament": "Tournament",
  "tournamentDoesNotExistOrIsDeleted": "This tournament does not exist, or has been deleted.",
  "tournamentHomepage": "TOURNAMENT HOMEPAGE",
  "tournamentName": "Tournament name",
  "tournamentOrganizer": "Tournament organizer",
  "tournaments": "Tournaments",
  "type": "Type",
  "upcomingTournaments": "Upcoming tournaments",
  "update": "Update",
  "userGuide": "User Guide",
  "username": "Username",
  "usernameExists": "Username exists, please select different username and try again",
  "viewGame": "View game",
  "waitingForGame": "Waiting for the next game",
  "weeks": "weeks",
  "weighted": "Weighted",
  "wellPlayed": "Well played",
  "whatIsFide": "What is a FIDE rating?",
  "white": "White",
  "whiteWins": "White wins, 1-0.",
  "whiteWon": "white won",
  "whyFide": "Why does SpillSjakk need my FIDE ID number?",
  "winPoints": "Points for a win",
  "yes": "Yes",
  "you": "You",
  "youHaveBeenInvited": "You’ve been invited to play for $name on SpillSjakk. It’s quick, easy and lets you play in their custom tournaments!",
  "nextGameIsIn": "your next game is in",
  "goToTournamentDetail": "Go to Tournament Detail",
  "tournamentSchedule": "Tournament Schedule",
  "recentTournament": "Recent Tournament",
  "list": "List",
  "miniboards": "Miniboards",
  "createOrganization": "Create Organization",
  "createOrg_name": "Organization's Name",
  "createOrg_nameDesc": "(this name will be publicly visible)",
  "createOrg_id": "Organization's URL (SpillSjakk ID): ",
  "createOrg_idDesc": "(this field will appear at the end of web URLs on SpillSjakk, e.g., spillsjakk.no/organization/view/CUSTOMURL)",
  "createOrg_desc": "About the Organization: ",
  "createOrg_descDesc": "(this is publicly viewable and is a chance to tell the public about your organization)",
  "createOrg_manager": "Organization Manager",
  "createOrg_managerDesc": "User id of the account that will manage the organization",
  "tournamentHistory": "Tournament History",
  "gameHistory": "Game History",
  "memberships": "Memberships & Affiliations",
  "ok": "OK",
  "userIconHelpbox": "This shows you are correctly logged in. You can click the icon to access your profile page, message inbox, and to log out. Try hovering over the icon and accessing your profile page",
  "logoHelpbox": "If you ever got lost, you can click on this logo to return to the homepage",
  "homeTournamentsHelpbox": "Public tournaments, or tournaments available for you to play in are displayed here",
  "userProfileTournamentHelpbox": "These are a record of the public games and tournaments you’ve played",
  "userProfileAccountSettingsHelpbox": "You can click this link to edit or change your email and password",
  "createAccountsActionHelpbox": "This is the manual account creation method. It gives you complete power to set the details of your players. If you need to make a lot of player accounts and trust those players, try sharing your Club’s unique invite link with your potential players under “Account Creation -> Invite",
  "createAccountsInputsHelpbox": "Usernames will be publicly displayed, so please make sure they are appropriate. Only yourself and Organisations your Club belongs to will see the real name of the players, except in certain tournaments. All other fields can be left blank, except Birth date and Sex, but these are only for record keeping, and do not need to be accurate. If you have the players FIDE ID, you can save time by entering that number first and the other relevant fields will automatically fill themselves",
  "generateInvitationLinkHelpbox": "This is the automatic account creation method. When the unique URL is shared with your potential players, they can follow it to set their own details. This is the best way to make lots of accounts quickly, but if you need more restricted access or control over the process, using manual account creation under “Account Creation -> Create Accounts” may be better.",
  "invitationLinkDescriptionHelpbox": "Please note, anyone with this link can make an account and be part of your Club or Organisation. However, you can remove unwanted accounts through the Club or Organisation Admin Panel",
  "clubManageDetailHelpbox": "This shows your Club’s details, some of which can be edited",
  "clubManageAccountsHelpbox": "Press expand all to see all of the accounts within the Club. You can remove an account from the Club by pressing the red cross next to their name. If you know an account’s Player ID, you can add them directly to the Club",
  "clubManageMessageHelpbox": "You can message all of the members in the Club - the message will go to their SpillSjakk inbox. You can also message just a specific team",
  "clubManageSharePowerHelpbox": "Some powers of the Club can be shared - you should only do this with people you absolutely trust, such as board members of the Club or other individuals with responsibility. Simply enter their Player ID, toggle the powers, and press the green box. That account will receive the additional powers",
  "clubManageStatsHelpbox": "This shows the number of teams and players - more statistics will be added here for Club use in the future",
  "clubManageTeamsHelpbox": "A Club can be split into Teams. A player will always belong to a Club, but may also belong to one or more teams within that Club. Enter the Team’s name, and press the green box to create it. You can then click the Team name you’ve created to add players to it and manage it. Clicking the red cross next to it deletes the Team.",
  "tournamentDetailManageTournamentHelpbox": "As the organiser of this tournament, you can click here to access additional administrative powers. Only you, or accounts with the same permissions you have given them, can see this.",
  "manageTournamentManageParticipantsHelpbox": "Add the groups of players you want to allow access to the tournament here. If self-joining is turned on, they will see a button on the tournament page letting them access the tournament. Otherwise, you can manually add just those you want to allow access to.",
  "manageTournamentChangeTimeHelpbox": "You can change the time of the next pairing either during a round, or x minutes before it’s scheduled to start.",
  "manageTournamentStandingsHelpbox": "After the tournament starts and results come in, you will also see pairings here. You can manually change the result of a game by clicking the score which should have been given.",
  "tournamentBuildBannerDesc": "for best results, please select an image which is 4x more wide than it is tall",
  "format": "Format",
  "howToMakeAccount": "How can I make an account?",
  "howToMakeAccountDesc": "Currently, SpillSjakk is still in active development, with major changes regularly. Trial accounts are being given out to certain schools, chess clubs, and initiatives in Norway. Unless invited from one of these early adopters, there is no way to make a general account right now.",
  "clubProfile": "Club Profile",
  "organizationProfile": "Organization Profile",
  "tournamentCalendar": "tournaments",
  "signup": "signup",
  "readMore": "read more",
  "homev2Desc": "An efficient pairing and playing tool for clubs and event organisers. Supporting online, over the board, or hybrid tournament and casual play.",
  "onSpillsjakk": "on SpillSjakk",
  "editAccount": "Edit Account",
  "edit": "Edit",
  "teamSize": "Team Size",
  "confirmRemoveUser": "Do you want to remove user?",
  "invitePairPlay": "Invite · Pair · Play",
  "soon": "soon",
  "later": "later",
  "today": "today",
  "realWorld": "Real World",
  "back": "Back",
  "customSettings": "Custom Settings",
  "buildTournament_chooseTemplateDesc": "Choose from saved templates or tweak every setting related to the tournament",
  "buildTournament_timeAllowance": "Time Allowance Per Player",
  "buildTournament_timeAdded": "Time Added After Each Move",
  "buildTournament_intervalGames": "Interval Between Games",
  "buildTournament_templateSelection": "Template Selection",
  "buildTournament_location": "Tournament Location",
  "buildTournament_reviewAll": "Review All",
  "buildTournament_useTemplate": "Use Template",
  "buildTournament_privacyAdvanced": "Privacy & Advanced Settings",
  "buildTournament_startDateTime": "Start Date and Time",
  "buildTournament_format": "Format",
  "buildTournament_timeControl": "Time Control",
  "buildTournament_pleaseSelectOrganiser": "Please Select Organiser",
  "moreInfo": "More Info",
  "rank": "Rank",
  "nextWithTemplate": "Next With Selected Template",
  "accountsWithPowers": "Accounts with Powers",
  "buildLeague": "Build League",
  "leagueHomepage": "League Homepage",
  "createSeason": "Create Season",
  "createCategory": "Create Category",
  "editSeason": "Edit Season",
  "editCategory": "Edit Category",
  "genderRestricted": "Gender Restricted",
  "ageRestricted": "Age Restricted",
  "ratingRestricted": "Rating Restricted",
  "minimumAge": "Minimum Age",
  "maximumAge": "Maximum Age",
  "minimumRating": "Minimum Rating",
  "maximumRating": "Maximum Rating",
  "downloadPgns": "Download Pgn File",
  "leagueManagePage": "League Management",
  "categories": "Categories",
  "seasons": "Seasons",
  "addCategory": "Add Category",
  "addSeason": "Add Season",
  "editLeague": "Edit League",
  "seasonAndCategory": "Season and Category",
  "league": "League",
  "messageAllClubMembers": "Message all club members",
  "userId": "User Id",
  "addTeams": "Add Teams",
  "alreadyAMember": "You are already a member",
  "delete": "Delete",
  "pairingGenerationFailed": "This tournament could not be completed due to insufficient players. All possible pairings have been exhausted.",
  "chatEnabled": "Chat Feature Enabled",
  "stats": "Statistics",
  "statsNumberOfPlayers": "Number of Players",
  "statsTitledPlayers": "Number of Titled Players",
  "statsCountries": "Number of Countries and Players participating",
  "statsAverageRating": "Average Rating",
  "statsLongestGame": "Longest Game",
  "statsShortestGame": "Shortest Game",
  "filterByClub": "Filter By Club",
  "lichessUsername": "Lichess Username",
  "linkLichessAccount": "Link Lichess Account",
  "end": "End",
  "promotion": "Promotion",
  "relegation": "Relegation",
  "promote": "Promote",
  "relegate": "Relegate",
  "pleaseSelectPlayer": "Please Select Player",
  "pleaseSelectTeam": "Please Select Team",
  "pleaseSelectCategory": "Please Select Category",
  "successful": "Successful",
  "error": "Error",
  "endSeasonAndStartNewOne": "End Season and Start New One",
  "startSeason": "Start Season",
  "saveAndCreate": "Create and Save as Template",
  "manageTeam": "Manage Team",
  "removeInactive": "Remove Inactive Players",
  "selectClub": "Select Club",
  "boardNumber": "Board Number",
  "print": "Print",
  "tomorrow": "Tomorrow",
  "updatePinSuccessfulMessage": "Tournament status is updated successfully",
  "pinnedOrder": "Order",
  "pinned": "Highlights",
  "tournamentTemplatePlaceholder": "Please Select a Template",
  "increment": "increment",
  "allTournaments": "All Tournaments",
  "leagues": "Leagues",
  "chess": "Chess",
  "antichess": "Antichess",
  "pawnVsPawn": "Pawn vs Pawn",
  "limitedPlayerTeam": "Four Player Team"
};

export { en };
