/* eslint-disable */
export default {
  "title1": "Tjenestevilkår - Brukeravtale",
  "p1_1": "Oppsummert – ivareta beste oppførsel mot hverandre, handle lovlig og ha det gøy.",
  "p1_2": "Les nøye gjennom hele denne avtalen før du bruker nettstedet vårt. Uten å påvirke dine lovbestemte rettigheter, er denne avtalen juridisk bindende mellom oss. Ved å bruke nettstedet vårt godtar du vilkårene i denne avtalen i sin helhet. Følgelig, hvis du ikke er enig, må du ikke bruke nettstedet vårt.",
  "p1_3": "Hvis eller når du registrerer deg på nettstedet vårt, sender innhold til nettstedet vårt eller bruker noen av nettstedstjenestene våre, vil vi be deg om å uttrykkelig godta denne avtalen. Du samtykker i at du er ansvarlig for overholdelse av gjeldende lokale lover.",
  "p1_4": "Ved å bruke nettstedet vårt eller godta vilkårene i denne avtalen, bekrefter og erklærer du at du er en voksen person med lovlig alder, eller har fått tillatelse og samtykke fra en forelder eller verge som er over 18 år for å inngå denne avtalen.",
  "p1_5": "Materialet på dette nettstedet er beskyttet av gjeldende lover om opphavsrett og varemerke.",
  "title2": "Brukerlisens",
  "p2_1": "Det gis tillatelse til midlertidig å se, laste ned, skrive ut, cache, strømme og bruke andre av nettstedstjenestene våre ved hjelp av en nettleser på nettstedet vårt for personlig, ikke-kommersiell forbigående bruk, underlagt de andre bestemmelsene i denne avtalen. Dette er tildeling av lisens, ikke overføring av tittel, og under denne lisensen kan du ikke:",
  "p2_2": "endre eller kopiere materialene, med mindre en spesifikk lisens tillater deg å gjøre det, og du overholder fullstendig den lisensen;",
  "p2_3": "bruke materialene til ethvert kommersielt formål, eller til kommersiell offentlig visning, med mindre en spesifikk lisens tillater deg å gjøre det, og du overholder fullstendig den lisensen;",
  "p2_4": "forsøke å dekompilere eller reversere programvare som finnes på nettstedet vårt, med mindre en spesifikk lisens tillater deg å gjøre det, og du overholder den lisensen fullt ut;",
  "p2_5": "fjerne enhver copyright eller annen proprietær notasjon fra nettstedet vårt;",
  "p2_6": "overføre materialene til en annen person eller speile materialene på en hvilken som helst annen server, med mindre en spesifikk lisens tillater deg å gjøre det, og du overholder fullstendig lisensen.",
  "p2_7": "Denne lisensen opphører automatisk hvis du bryter med noen av disse begrensningene, underlagt de andre bestemmelsene i denne avtalen, og du godtar at vi med eller uten grunn og uten forvarsel umiddelbart kan avslutte din spillsjakk.no-konto, tilknyttet e-postadresse, og tilgang til nettstedet vårt. Du samtykker i at alle oppsigelser skal skje etter spillsjakk.no sitt eget skjønn, og at spillsjakk.no ikke er ansvarlig overfor deg eller noen tredjepart for noen avslutning av kontoen din, tilknyttet e-postadresse eller tilgang til nettstedet.",
  "title3": "Akseptabel bruk",
  "p3_1": "Du må ikke bruke nettstedet vårt på noen måte eller gjøre noen tiltak som forårsaker eller kan forårsake skade på nettstedet vårt, levering av tjenester, infrastruktur, eller ytelsen, tilgjengeligheten eller tilgjengeligheten til nettstedet, levering av tjenester og infrastrukturen. Videre kan du ikke bruke nettstedet vårt på noen måte som er ulovlig, uredelig eller skadelig, eller i forbindelse med ulovlig, uredelig eller skadelig aktivitet. Du kan ikke utføre systematiske eller automatiserte datainnsamlingsaktiviteter (inkludert uten begrensning skraping, datautvinning, datautvinning og datainnhøsting) på eller i forhold til nettstedet vårt uten vårt eksplisitte skriftlige samtykke, eller samhandle med nettstedet vårt ved hjelp av noen robot, edderkopp eller andre automatiserte midler unntatt indeksering av søkemotorer. Du kan ikke bruke data samlet inn fra nettstedet vårt for direkte markedsføringsaktivitet, eller for å kontakte enkeltpersoner, selskaper eller andre personer eller enheter.",
  "title4": "Ansvarsfraskrivelse",
  "p4_1": "Materialet på nettstedet vårt leveres 'som det er'. Vi gir ingen garantier, uttrykt eller underforstått, og fraskriver og negerer herved alle andre garantier, inkludert uten begrensning, underforståtte garantier eller betingelser for salgbarhet, egnethet for et bestemt formål eller ikke-krenkelse av åndsverk eller annen rettighetsbrudd. Videre garanterer vi ikke eller fremsetter noen uttalelser om nøyaktigheten, sannsynlige resultatene av påliteligheten av bruken av materialene på nettstedet vårt eller på annen måte knyttet til slikt materiale på noen nettsteder som er koblet til dette nettstedet.",
  "title5": "Begrensninger",
  "p5_1": "Under ingen omstendigheter skal vi eller våre leverandører være ansvarlige for skader (inkludert, uten begrensning, skader for tap av data eller fortjeneste, skader for skade eller på grunn av forretningsforstyrrelser) som skyldes bruk eller manglende evne til å bruke materialet på vår nettside, uavhengig av hvilke handlinger vi kan eller ikke har gjort, i den grad det er gitt av Norges lover.",
  "title6": "Revisjoner og feil",
  "p6_1": "Materialene som vises på nettstedet vårt kan inneholde tekniske, typografiske eller fotografiske og grafiske feil. Vi garanterer ikke at alt av materiell på nettstedet vårt er nøyaktig, komplett eller oppdatert. Vi kan når som helst gjøre endringer i materialene på nettstedet vårt uten varsel. Vi forplikter oss ikke til å oppdatere materialene.",
  "title7": "Lenker",
  "p7_1": "Vi har ikke gjennomgått alle nettstedene som er tilkoblet på nettstedet vårt, og vi er ikke ansvarlige for innholdet på et slikt koblet tredjeparts nettsted. Inkluderingen av noen lenker innebærer ikke at vi godkjenner nettstedet. Bruk av et slikt koblet nettsted er på brukerens egen risiko.",
  "title8": "Nettstedsbetingelser Modifikasjoner",
  "p8_1": "Vi kan revidere denne avtalen når som helst uten varsel, i den grad det er gitt oss av norsk lov. Ved å bruke dette nettstedet godtar du å være bundet av den gjeldende versjonen av denne avtalen, og når den endres, vil din fortsatte bruk bli tatt for å godta den nye avtalen.",
  "title9": "Oppdrag",
  "p9_1": "Du godtar herved at vi kan tildele, overføre, 'subcontracting' eller på annen måte håndtere våre rettigheter og / eller forpliktelser i henhold til denne avtalen, og du kan ikke uten vårt forutgående skriftlige samtykke tildele, overføre, underentreprise eller på annen måte håndtere noen av dine rettigheter og / eller forpliktelser under disse vilkårene.",
  "title10": "Separasjonsevne",
  "p10_1": "Hvis en bestemmelse i denne avtalen er bestemt av en domstol eller annen kompetent myndighet som ulovlig og / eller ikke kan håndheves, vil de andre bestemmelsene fortsette å gjelde.",
  "title11": "Jurisdiksjon",
  "p11_1": "Ethvert krav relatert til nettstedet vårt skal være underlagt Norges lover, uten hensyn til dets bestemmelser vedrørende lovkonflikt.",
  "title12": "Uformell oppløsning",
  "p12_1": "Du vil først kontakte oss på hei@spillsjakk.no for å varsle oss om enhver tvist og forsøke å løse den uformelt. Vi vil i god tro forsøke å forhandle om en løsning på tvisten i minst tretti (30) dager fra datoen vi mottar korrespondansen din.",
  "p12_2": "Enkelte arrangører kan ha sin egen vei for uformell løsning, potensielt inkludert egne ankekomiteer, kommisjoner eller paneler. Når dette er tilfelle, godtar du å følge prosedyrene for dem og frafalle oss ansvaret der tvisten er dekket av en annen parts vilkår.",
  "title13": "Retningslinjer for rettferdig spill og fellesskap",
  "p13_1": "Nettstedet vårt har plikten og ansvaret for å opprettholde en rettferdig plattform for sjakkspillere. Vi forbeholder oss retten til å overvåke alle sjakkspill som spilles på nettstedet vårt, ta statistikk og beregninger av sjakkspillene som spilles på nettstedet vårt, og i tråd med vår personvernpolicy, å dele disse spillene der våre egne kontraktsavtaler er avhengig av deling av disse dataene, beregningene og statistikken.",
  "p13_2": "Det er vårt skjønn om våre retningslinjer for rettferdig spill og fellesskap er brutt. Vi forbeholder oss retten til å lukke kontoer uten advarsel, og å trekke tjenestene våre fra de vi har funnet å ha brutt våre retningslinjer for rettferdig spill og fellesskap.",
  "p13_3": "Sjakktrekkene dine må være dine egne",
  "p13_4": "Du må holde passordet ditt privat",
  "p13_5": "Du godtar å ta ansvar for sikkerheten til kontoen din",
  "p13_6": "Du har ikke lov til å jukse",
  "p13_7": "Du kan ikke søke ekstern hjelp, enten fra en annen person eller fra programvare eller andre ressurser",
  "p13_8": "Du kan ikke utgi deg for noen du ikke er",
  "p13_9": "Du kan ikke dele spillerkontoen din med noen andre",
  "p13_10": "Du kan ikke spille fra en annen spillerkonto enn din egen",
  "p13_11": "Du kan ikke kunstig manipulere rangeringer, treff eller resultater",
  "p13_12": "Du kan ikke forstyrre spillet til en annen person",
  "p13_13": "Du kan ikke prøve å få tilgang til en annen persons konto",
  "p13_14": "Du kan ikke handle på en trakasserende, truende eller forfølge brukerne våre",
  "p13_15": "Du kan ikke dele materiale du ikke har rett til å dele",
  "p13_16": "Du samtykker i å handle i samsvar med lovgivningen i Norge",
  "p13_17": "Du samtykker i å behandle andre vennlig og forsiktig",
  "p13_18": "Denne listen er ikke uttømmelig og kan endres."
}
