/* eslint-disable no-irregular-whitespace */
export function getDictionary(lang: string) {
  const dictionary: any = {
    EN: {
      title: "SpillSjakk User Guide",
      p1: "Depending on the type of account you have, you may not have access to all the abilities outlined in this user guide.",
      p2: "If the tournament is over the board and not online, stages 1 - 4 don’t apply.",
      index: "index:",
      c1: "Getting an account and logging in",
      c2: "Creating a club",
      c3: "Creating player accounts",
      c4: "Adding and removing accounts to a club",
      c5: "Adding and removing clubs to an organisation",
      c6: "Setting up a tournament",
      c6a: "More information on each tournament setting and tournament formats",
      c7: "Adding players to a tournament",
      c8: "Starting the tournament",
      c9: "Editing/deleting tournaments, changing future round timings",
      c10: "Accessing tournament printouts",
      c11: "Inputting or changing results",
      c12: "Checking club/organisation statistics",
      c1p1: "There are a few different types of account, with different powers and permissions.",
      c1p2: "Player accounts can be made by anyone with a Club Manager or Organisation account. Club Manager accounts can only be made by those with an Organisation account.",
      c1p3: "A player account is not needed for solely over the board pairings. For help on adding offline players for an entirely over the board event, section 6 has further information.",
      c1p4: "Some chess federations offer accounts for their members. It may be worth contacting your chess federation to see if they have an agreement with our service.",
      c1p5: "Otherwise, you can purchase a Club Manager or Organisation account, based on your needs and the relevant pricing. ",
      c1p6: `To log in, click the "log in" button on the top right, and enter your username and then your password. If you have forgotten your password but have an email set on the account, you can follow the "forgotten password?" link to recover your account. `,
      c1p7: `Once you are logged in successfully, “log in” on the top right should be replaced by your username.`,
      c2p1: "The fastest way to create a club is via the dropdown on the top bar. So long as your account’s permissions are Club Manager or Organisation, you can create a club. ",
      c2p2: `On the top bar of the page, click “manage”. A drop-down list will appear, and then choose “create club”.`,
      c2p3: `You can also access it any time, on the following URL: https://spillsjakk.no/club/manage`,
      c2p4: `Several fields will appear. “Name” is what will appear as the public club name. “ID” is the unique URL ending you can give for your club. “Description” is a field for information or further details about your club. “Country” allows you to select your country. “Region” is only active for Norway presently. `,
      c2p5: `“Join Organisation” allows you to fall under the umbrella of, for example, a national federation. You will need to know their ID, which is often their name. Based on the country entered, you will always be at least part of your Federation Organisation. `,
      c2p6: `Clubs can be further split into “teams”. You can give the team a unique name (for example, Club Division 1 Team) and sort your players into rosters. `,
      c3p1: `The fastest way to create player accounts for online play is via the dropdown on the top bar. So long as your account’s permissions are Club Manager or Organisation, you can create player accounts.`,
      c3p2: `On the top bar of the page, click “manage”. A drop-down list will appear, and then choose “Create Accounts”.`,
      c3p3: `You can also access it at any time, on the following URL: https://spillsjakk.no/account/create`,
      c3p4: `If you know your player’s FIDE number, you can simply enter this number into the “FIDE number” field. Once you enter the number and click out of the field, it will automatically enter the rest of the information FIDE has on the player. `,
      c3p5: `Once you are happy with the information you have entered for a player, click the + button on the right side of the screen. `,
      c3p6: `This will then generate the username (based on firstname and lastname) and a unique password for the player(s).`,
      c3p7: `You can continue adding players, and then copy and paste their usernames and passwords, or download the list as CSV (a spreadsheet file). This is the only time you will be able to see a user’s password.`,
      c3p8: `Once the user has logged in, they can change their password under account settings (click on your username -> go to profile page -> under your name “account settings”).`,
      c4p1: `On the top bar of the page, click “manage”. A drop down list will appear. Select “manage club” and click it. Towards the bottom of the page, find “add member”. `,
      c4p2: `Enter their user ID (username) into the field, and press the + button to add them. This allows the Club Manager to only admit the users they know are part of the club.`,
      c4p3: `To remove an account from a club, click the red X mark next to their name. `,
      c5p1: `On the top bar of the page, click “manage”. A drop down list will appear. Select “manage organisation” and click it. Towards the bottom of the next page, find “add a club”.`,
      c5p2: `Enter the club’s ID into the field, and press the + button to add them. This allows the organisation to only admit the users they know are part of their organisation.`,
      c5p3: `To remove an account from a club, click the red X mark next to their name.`,
      c6p1: `On the top bar of the page, click “manage”. A drop down list will appear. Select “Build tournament” and click it. `,
      c6p2: `Alternatively, on the home page you can click the “build a tournament” button. `,
      c6p3: `The direct URL can be found here: https://spillsjakk.no/tournament/build`,
      c6p4: `You will be shown a number of fields, some of which are mandatory to fill out to proceed. `,
      c6p5: `Alternatively you can select a template tournament, which will create a tournament for you using common settings. `,
      c6p6: `Once you’ve set up the tournament with the settings you would like, click the “build and invite players” button. `,
      c6ah1: `Settings:`,
      c6ap1: `“Tournament name” - this is what the publicly displayed name of the tournament will be`,
      c6ap2: `“ID” - this is the unique URL for the tournament. A template tournament gives a random ID.`,
      c6ap3: `“Description” - this is the “about” section of the tournament. It will be publicly viewable. Images hosted on imgur.com or photobucket.com can be embedded in this section. YouTube or Twitch links can also be embedded in this section.`,
      c6ap4: `“Start date” - when the tournament is due to start`,
      c6ap5: `“End date” - when the tournament is due to end`,
      c6ap6: `“Publicly viewable” - anyone will be able to see the tournament. This option should not be selected if the tournament is entirely private, or played by more vulnerable sectors of society.`,
      c6ap7: `“Allow self-joining” - this allows anyone to join the tournament. `,
      c6ap8: `“FIDE rated tournament” - this will calculate the change in ratings, using FIDE algorithms. It does not actually change their FIDE rating, just estimates the change.`,
      c6ap9: `“Only show scores of individuals or teams in the top n” - publicly displays only the scores of the top teams or individuals, for example inputting “3” would publicly show only the scores of the podium placers. The host of the tournament can see all results.`,
      c6ap10: `“Default game location” - whether the games will be played online, or offline. If “over the board” is selected, no online player accounts are needed, and no online games will be generated. Tournament print-outs and all other tools are available for either online or over the board tournaments.`,
      c6ap11: `“First online pairing date/time” - only required for online games. This will generate the correct online game and pairing for the online players in the tournament, with clocks starting at the selected date/time. This can be changed, even whilst the tournament is live, so long as there is more than 10 minutes before the next round is due to start. `,
      c6ap12: `“Interval between online rounds” - this is how long players will have to wait before their next online round. This can be edited during the tournament, so long as there is more than 10 minutes before the next round is due to start.`,
      c6ap13: `“Time control” - the time given to the players on the clock. Seconds are given in increments.`,
      c6ap14: `“Points for a win/draw/loss” - defaults as 1 / 0.5 / 0, but can be freely edited in 0.5 increments.  `,
      c6ah2: `Tournament formats:`,
      c6ap15: `“Knockout” - team or individual, requires participant numbers to be to the power of 2 (for example, 2, 4, 8, 16, 32, 64, etc). Creates a “bracket” or “ladder”. Each team or individual will play two games against their opponent, switching colours. Ties are settled by sudden death - additional games are played until there is a winner. Losing teams or players are eliminated from the tournament. `,
      c6ap16: `“Swiss (Dutch)” - team or individual. The classic chess tournament format - full pairing rules are available in the FIDE Handbook. There must be more players than there are rounds, and certain combinations are likely to fail given the Swiss (Dutch) ruleset (in particular 4 players with 3 rounds, and 5 players with 4 rounds - a round robin may be wiser in these situations).`,
      c6ap17: `“Skolesjakken” - team only. This is a popular Scandinavian tournament setting in scholastic chess. Points are awarded to teams not just for victory, but also for the number of players they attract. The full rules are available on the Sjakk5ern website.`,
      c6ap18: `“Konrad” - team or individual. This is a popular Scandinavian tournament setting in club chess. Winners play winners, and late joiners are seeded directly by their rating. There are minimal colour-preferences, allowing a small number of players to play a high number of rounds. `,
      c6ap19: `“Round-robin” - team or individual. Each player meets each player in turn. They can meet as many times as chosen in the settings - ordinarily this will be twice, allowing for switched colours. `,
      c7p1: `Once you have built a tournament, you will be taken to its unique tournament page. `,
      c7p2: `Beneath the information about the tournament, the creator of the tournament can see a “Manage participants” button. `,
      c7p3: `Click the “manage participants” button. Scroll down the page to “Add a club”. You can add any club which you or your organisation owns. You can either add the entire club (allowing everyone in the club to play), or specific teams of the club (eg, just the first division team).`,
      c7p4: `This allows a major organisation, such as an entire Federation, to create a “national league” team, or similar. This allows them to add the entire team in just two clicks.`,
      c7p5: `
If self-joining has been selected, anyone in the club or team can register themself to play. When logged in on their player account, under the information about the tournament they will see a “join” button.`,
      c7p6: `
If self-joining hasn’t been selected, the tournament organiser has to choose the accounts which will be playing.`,
      c7p7: `To do this, once the club/team is added to the event (by clicking the + arrows, remove with the x crosses), click the “update” button, and then “back to tournament”.`,
      c7p8: `
Under the tournament information, you will now see a new button “Manage [“Club name”]” which lets you add the online players from that club or team. `,
      c7p9: `If the tournament is over the board, there is no need for a player account, and a tournament organiser can add players directly on this screen. You can do this just by inputting FIDE numbers (if you have them), which will then pull all the other information in the fields from the FIDE database. `,
      c7p10: `Once all players are added to the event (by clicking the + arrows, or removing with the x crosses), click the “update” button, and then “back to tournament”.`,
      c8p1: `Once all players have been added to the tournament, press the “start” button under the tournament information.`,
      c8p2: `At this point, there will be no way to add further players, or if self-joining is enabled, no more self-joining will be allowed. `,
      c8p3: `10 minutes before the first online round, an orange bar will appear at the top of the screen, inviting the players to a lobby page. The lobby page countdowns until the first pairing, before automatically redirecting the players to their correct opponent.`,
      c9p1: `It is possible to delete a tournament, so long as the first round has not started. The tournament organiser will be able to see a “delete” button under the tournament information which only they can see and interact with. Confirmation is required, as there is no way of recovering a deleted tournament. `,
      c9p2: `It is possible to edit a tournament which has already been set up, but not all fields can be edited. The tournament format, for example, can’t be edited once it has been created. Once the first pairing has occurred, editing is restricted just to the tournament description and other superficial fields. `,
      c9p3: `The timing of a future round can be changed by changing the date/time under the tournament information section, on the tournament page. This must be done more than 10 minutes before the round is due to take place. `,
      c10p1: `Under the tournament information section, the tournament organiser has access to result tables, pairing tables, and board cards - all of which can be printed out. `,
      c10p2: `Players of the events can see changes and updates in realtime, as they happen, so long as they have access to the tournament page - either as results are input by the tournament organiser for over the board play, or as online games conclude.`,
      c11p1: `The tournament organiser is able to input or edit results, even those played online.`,
      c11p2: `Scroll down to the end of the tournament page, and you will see a list of results. Click on the required result, and the standings will update. `,
      c11p3: `Online games automatically record results, but the tournament organiser can override them, simply by clicking the result required between the players. `,
      c11p4: `If results are changed before the next pairing takes place, this will affect the future pairings. `,
      c12p1: `Some statistics are available to clubs and organisations about their membership and their tournaments.`,
      c12p2: `The organisation or club account must go to their club or organisation page. There, under their club or organisation information is a “statistics” section.`,
    },
    NO: {
      title: "SpillSjakk brukerhåndbok",
      p1: "Avhengig av hvilken type konto du har, har du kanskje ikke tilgang til alle funksjonene som er beskrevet i denne brukerhåndboken.",
      p2: "Hvis turneringen er over brettet, gjelder ikke trinn 1 - 4 som omhandler online spill.",
      index: "Indeks:",
      c1: "Kontotilgang og innlogging",
      c2: "Opprette en klubb",
      c3: "Opprette spillerkontoer",
      c4: "Legge til og fjerne kontoer i en klubb",
      c5: "Legge til og fjerne klubber i en organisasjon",
      c6: "Sette opp en turnering",
      c6a: "Mer informasjon om turneringsformat og innstillinger",
      c7: "Legge til spillere i en turnering",
      c8: "Starte turneringen",
      c9: "Redigere / slette turneringer, endre fremtidige rundetider",
      c10: "Få tilgang til turneringsutskrifter",
      c11: "Skrive inn eller endre resultater",
      c12: "Klubb- og organisasjonsstatistikk",
      c1p1: `Det er flere typer kontoer, med forskjellige fullmakter og tillatelser.`,
      c1p2: "Spillerkontoer kan lages av alle som har en klubbleder- eller organisasjonskonto. Klubbleder-kontoer kan bare opprettes av de som har en organisasjonskonto.",
      c1p3: "En spillerkonto er ikke nødvendig for spill over brett. Informasjon om hvordan å legge til offline spillere finnes i seksjon 6 .",
      c1p4: "Noen Sjakkforbund tilbyr kontoer for sine medlemmer. Det kan være verdt å kontakte sjakkforbundet for å se om de har avtale med tjenesten vår.",
      c1p5: "Ellers kan du kjøpe en klubbleder- eller organisasjonskonto, basert på dine behov og relevant pris.",
      c1p6: `For å logge inn, klikk på "logg inn" -knappen øverst til høyre, og skriv inn brukernavnet ditt og deretter passordet ditt. Hvis du har glemt passordet ditt, men har en e-post tilknyttet kontoen, kan du følge lenken "glemt passord?" for å gjenopprette kontoen din.`,
      c1p7: `Når innlogging er vellykket, skal “logge inn” øverst til høyre erstattes av brukernavnet ditt.`,
      c2p1: "Den raskeste måten å opprette en klubb på er ved å åpne rullegardinmenyen på den øverste linjen. Så lenge tillatelsene til kontoen din er klubbleder eller organisasjon, kan du opprette en klubb.",
      c2p2: `Klikk på "administrer" øverst på siden. En rullegardinliste vil vises, og velg deretter “opprett klubb”.`,
      c2p3: `Du kan også få tilgang til den når som helst, på følgende URL: https://spillsjakk.no/club/manage`,
      c2p4: `Av flere felt vil “Navn” vises som det offentlige klubbnavnet. “ID” er den unike URL-eningen du kan gi for klubben din. “Beskrivelse” er et felt for informasjon eller ytterligere detaljer om klubben din. "Land" lar deg velge ditt land. “Region” er for tiden bare aktiv for Norge.`,
      c2p5: `"Bli med i organisasjonen" lar deg falle under paraplyen til for eksempel et nasjonalt forbund. Du må vite deres ID, som ofte er navnet deres. Basert på landet du har angitt, vil du alltid være minst en del av ditt forbund.`,
      c2p6: `Klubber kan deles videre i "lag". Du kan gi laget et unikt navn (for eksempel Klubb Division 1 Lag) og sortere spillerne dine i lister.`,
      c3p1: `Den raskeste måten å opprette spillerkontoer for online spill er via rullegardinmenyen på den øverste linjen. Så lenge tillatelsene til kontoen din er klubbleder eller organisasjon, kan du opprette spillerkontoer.`,
      c3p2: `Klikk på "administrer" øverst på siden. En rullegardinliste vises, og velg deretter "Opprett kontoer".`,
      c3p3: `Du kan også få tilgang til det når som helst, på følgende URL: https://spillsjakk.no/account/create`,
      c3p4: `Hvis du kjenner spillerens FIDE-nummer, kan du ganske enkelt skrive dette nummeret i "FIDE-nummer" -feltet. Når du har tastet inn nummeret og klikket ut av feltet, vil det automatisk legge inn resten av informasjonen FIDE har på spilleren.`,
      c3p5: `Når du er fornøyd med informasjonen du har angitt for en spiller, klikker du på + -knappen på høyre side av skjermen.`,
      c3p6: `Dette vil da generere brukernavnet (basert på fornavn og etternavn) og et unikt passord for spilleren / spillerne.`,
      c3p7: `Du kan fortsette å legge til spillere, og deretter kopiere og lime inn brukernavn og passord, eller laste ned listen som CSV (en regneark-fil). Dette er den eneste gangen du kan se passordet til en bruker.`,
      c3p8: `Når brukerne har logget inn, kan de endre passordet sitt under kontoinnstillinger (klikk på brukernavnet ditt -> gå til profilsiden -> under navnet ditt "kontoinnstillinger").`,
      c4p1: `Klikk på "administrer" øverst på siden. En rullegardinliste vises. Velg "administrer klubb" og klikk på den. Mot bunnen av siden, finn "legg til medlem".`,
      c4p2: `
Skriv inn bruker-ID (brukernavn) i feltet, og trykk på + -knappen for å legge dem til. Dette gjør at klubbledere kun kan ta imot spillerne de vet er en del av klubben.`,
      c4p3: `For å fjerne en konto fra en klubb, klikk på det røde X-merket ved siden av navnet.`,
      c5p1: `Klikk på "administrer" øverst på siden. En rullegardinliste vises. Velg “administrer organisasjon” og klikk på den. Mot bunnen av neste side, finn "legg til klubb".`,
      c5p2: `Skriv inn klubbens ID i feltet, og trykk på + -knappen for å legge dem til. Dette gjør at organisasjonen kun kan ta imot spillerne de vet er en del av organisasjonen.`,
      c5p3: `For å fjerne en konto fra en klubb, klikk på det røde X-merket ved siden av navnet.`,
      c6p1: `Klikk på "administrer" øverst på siden. En rullegardinliste vises. Velg “Opprett turnering” og klikk på den.`,
      c6p2: `Alternativt kan du klikke på "Opprett turnering" -knappen på hjemmesiden.`,
      c6p3: `Den direkte URL-en finner du her: https://spillsjakk.no/tournament/build`,
      c6p4: `Du får vist et antall felt, hvorav noen er obligatoriske å fylle ut for å fortsette.`,
      c6p5: `Alternativt kan du velge turnering ut fra en mal, som vil lage en turnering for deg ved hjelp av vanlige innstillinger.`,
      c6p6: `Når du har satt opp turneringen med de innstillingene du ønsker, klikker du på "opprett og inviter spillere" -knappen.`,
      c6ah1: `Innstillinger:`,
      c6ap1: `“Turneringsnavn” - dette er navnet på turneringen som vises offentlig`,
      c6ap2: `“ID” - dette er den unike URL-en for turneringen. En turnering fra mal gir en tilfeldig ID.`,
      c6ap3: `"Beskrivelse" - dette er "om" -delen av turneringen. Det vil være offentlig synlig. Bilder vert på imgur.com eller photobucket.com kan være innebygd i denne delen. YouTube- eller Twitch-lenker kan også være innebygd i denne delen.`,
      c6ap4: `“Startdato” - når turneringen skal starte`,
      c6ap5: `“Sluttdato” - når turneringen skal avsluttes`,
      c6ap6: `“Offentlig synlig” - alle vil kunne se turneringen. Dette alternativet bør ikke velges hvis turneringen er helt privat, eller spilles av mer sårbare sektorer i samfunnet.`,
      c6ap7: `“Tillat selvtilslutning” - dette gjør at alle kan delta i turneringen.`,
      c6ap8: `“FIDE-rangerturnering” - dette vil beregne endringen i rangeringer ved hjelp av FIDE-algoritmer. Det endrer faktisk ikke FIDE-karakteren deres, bare estimerer endringen.`,
      c6ap9: `“Vis bare score for enkeltpersoner eller lag i toppen” - viser bare resultatene til topplagene eller enkeltpersonene offentlig, for eksempel ved å legge inn “3” vil offentlig bare vise poengsummen til pallplasseringene. Verten for turneringen kan se alle resultatene.`,
      c6ap10: `"Nett eller brett" - om partiene skal spilles online eller offline. Hvis “over brettet” er valgt, er det ikke behov for noen spillerkontoer på nettet, og ingen online spill vil bli generert. Turneringsutskrifter og alle andre verktøy er tilgjengelige for enten online- eller over brettet-turneringer.`,
      c6ap11: `“Dato og tid for første runde” er bare påkrevd for online-spill. Dette vil generere riktig rundeoppsett for spillere i turneringen med klokker som starter på valgt dato / tid. Dette kan endres, selv om turneringen er live, så lenge det er mer enn 10 minutter før neste runde skal starte.`,
      c6ap12: `“Intervall mellom online runder” - dette er hvor lenge spillere må vente før neste online runde. Dette kan redigeres under turneringen, så lenge det er mer enn 10 minutter før neste runde skal starte.`,
      c6ap13: `“Tidskontroll” - tiden som spillerne har til disposisjon.`,
      c6ap14: `“Poeng for seier / uavgjort / tap” - er standard som 1 / 0.5 / 0, men kan redigeres fritt i trinn på 0,5.`,
      c6ah2: `Turneringsformater:`,
      c6ap15: `“Knockout” - lag eller individuell, krever at deltakertallene er i kraft av 2 (for eksempel 2, 4, 8, 16, 32, 64, osv.). Oppretter en "brakett" eller "stige". Hvert lag eller individ vil spille to kamper mot motstanderen og bytter farge. Uavgjort avgjøres ved «sudden death» - flere partier spilles til det er en vinner. Tapende lag eller spillere blir eliminert fra turneringen.`,
      c6ap16: `“Sveitsisk (nederlandsk)” - lag eller individuell. Det klassiske turneringsformatet - komplette regler for rundeoppsett er tilgjengelig i FIDE-håndboken. Det må være flere spillere enn det er runder, og visse kombinasjoner vil sannsynligvis mislykkes med tanke på det sveitsiske (nederlandske) regelsettet (spesielt 4 spillere med 3 runder og 5 spillere med 4 runder – et Bergeroppsett kan være klokere i disse situasjonene).`,
      c6ap17: `“Skolesjakken” - kun lag. Dette er en populær skandinavisk turneringsinnstilling i skolastisk sjakk. Poeng tildeles lag ikke bare for seier, men også for antall spillere de tiltrekker seg. De fulle reglene er tilgjengelige på nettstedet Sjakk5ern.`,
      c6ap18: `“Konrad” - team eller individ. Dette er en populær skandinavisk turneringsinnstilling i klubbsjakk. Vinnere spiller vinnere, og sene deltagere blir sådd direkte av deres vurdering. Det er minimale fargeinnstillinger, slik at et lite antall spillere kan spille et stort antall runder.`,
      c6ap19: `“Berger” - team eller individ. Hver spiller møter hver spiller etter tur. De kan møtes så mange ganger som valgt i innstillingene - vanligvis vil dette være to ganger, slik at du kan bytte farger.`,
      c7p1: `Når du har opprettet en turnering, blir du ført til den unike turneringssiden.`,
      c7p2: `Under informasjonen om turneringen kan skaperen av turneringen se knappen “Administrer deltakere”.`,
      c7p3: `Klikk på “Administrer deltakerne” -knappen. Rull nedover på siden til “Legg til en klubb”. Du kan legge til en hvilken som helst klubb du eller din organisasjon eier. Du kan enten legge til hele klubben (slik at alle i klubben kan spille), eller spesifikke lag i klubben (f.eks. Bare førstedivisjonslaget).`,
      c7p4: `Dette gjør det mulig for en større organisasjon, for eksempel et helt forbund, å opprette et "nasjonale liga" -lag eller lignende. Dette gjør at de kan legge til hele teamet med bare to klikk.`,
      c7p5: `Hvis «Melde seg på selv» er valgt, kan alle i klubben eller laget registrere seg selv for å spille. Når du er logget inn på spillerkontoen, vil de under informasjonen om turneringen se "bli med" -knappen.`,
      c7p6: `Hvis ikke «Melde seg på selv» er valgt, må turneringsarrangøren velge kontoene som skal spilles.`,
      c7p7: `For å gjøre dette, når klubben / laget er lagt til arrangementet (ved å klikke på + pilene, fjerner med x kryssene), klikker du på "oppdater" -knappen, og deretter "tilbake til turneringen".`,
      c7p8: `Under turneringsinformasjonen vil du nå se en ny knapp “Administrer [“ Klubbnavn ”]” som lar deg legge til online spillere fra den klubben eller laget.`,
      c7p9: `Hvis turneringen spilles over brettet, er det ikke behov for en spillerkonto, og en turneringsarrangør kan legge til spillere direkte på skjermen. Du kan gjøre dette ved å legge inn FIDE-numre (hvis du har dem), som deretter vil hente all annen informasjon i feltene fra FIDE-databasen.`,
      c7p10: `Når alle spillerne er lagt til arrangementet (ved å klikke på + pilene, eller fjerne med x kryssene), klikker du på "oppdater" -knappen, og deretter "tilbake til turneringen".`,
      c8p1: `Når alle spillerne er lagt til turneringen, trykker du på “start” -knappen under turneringsinformasjonen.`,
      c8p2: `På dette tidspunktet vil det ikke være noen måte å legge til flere spillere, eller hvis «Melde seg på selv» er aktivert, vil det ikke lenger være tillatt på melde seg på selv.`,
      c8p3: `10 minutter før første online runde vises en oransje linje øverst på skjermen som inviterer spillerne til en lobbyside. Lobby-siden teller ned til den første sammenkoblingen, før den automatisk omdirigerer spillerne til riktig motstander.`,
      c9p1: `Det er mulig å slette en turnering, så lenge første runde ikke har startet. Turneringsarrangøren vil kunne se en “slett” -knapp under turneringsinformasjonen som bare de kan se og samhandle med. Bekreftelse er nødvendig, da det ikke er noen måte å gjenopprette en slettet turnering.`,
      c9p2: `Det er mulig å redigere en turnering som allerede er satt opp, men ikke alle felt kan redigeres. Turneringsformatet kan for eksempel ikke redigeres når det er opprettet. Når den første sammenkoblingen har skjedd, er redigering begrenset til turneringsbeskrivelsen og andre overfladiske felt.`,
      c9p3: `Tidspunktet for en fremtidig runde kan endres ved å endre dato / tid under seksjonen om turneringsinformasjon, på turneringssiden. Dette må gjøres mer enn 10 minutter før runden skal finne sted.`,
      c10p1: `Under delen om turneringsinformasjon har turneringsarrangøren tilgang til resultattabeller, rundeoppsett og bordkort - som alle kan skrives ut.`,
      c10p2: `Spillerne som deltar kan se endringer og oppdateringer i sanntid når de skjer, så lenge de har tilgang til turneringssiden - enten når resultatene blir lagt inn av turneringsarrangøren for spill over brettet, eller når online-spill avsluttes.`,
      c11p1: `Turneringsarrangøren kan legge inn eller redigere resultater, selv de som spilles online.`,
      c11p2: `Rull ned til slutten av turneringssiden, så ser du en resultatliste. Klikk på ønsket resultat, og stillingen oppdateres.`,
      c11p3: `Online spill registrerer automatisk resultater, men turneringsarrangøren kan overstyre dem ved å klikke på resultatet som kreves mellom spillerne.`,
      c11p4: `Hvis resultatene endres før neste rundeoppsett finner sted, vil dette påvirke fremtidige rundeoppsett.`,
      c12p1: `Noe statistikk er tilgjengelig for klubber og organisasjoner om deres medlemskap og turneringer.`,
      c12p2: `Organisasjonen eller klubblederen må gå til klubb- eller organisasjonssiden. Der, under deres klubb eller organisasjon, er det en "statistikk" -avdeling.`,
    },
  };
  return {
    getString: function (key: string) {
      let result: string;
      try {
        result = dictionary[lang][key];
        if (!(key in dictionary[lang])) {
          result = dictionary.EN[key];
        }
      } catch (e) {
        result = dictionary.EN[key];
      }
      return result;
    },
  };
}
