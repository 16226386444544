/* eslint-disable */
export default {
  "title1": "Personvernerklæring",
  "p1_1": "Denne personvernerklæringen forteller deg hva du kan forvente at vi gjør med din personlige informasjon når du tar kontakt med oss, registrerer en konto hos oss eller bruker en av våre tjenester.",
  "p1_2": "Denne kunngjøringen er indeksert. Så hvis du ønsker det, kan du enkelt velge grunnen til at vi behandler din personlige informasjon og se hva vi gjør med den.",
  "p1_3": "Denne kunngjøringen gjelder ikke praksis fra tredjeparter, som også kan samle inn eller motta data i forbindelse med din bruk av nettstedet vårt.",
  "title2": "Detaljer om kontrollør av data",
  "p2_1": "Spillsjakk.no er behandlingsansvarlig for personlig informasjon vi behandler, med mindre annet er oppgitt.",
  "p2_2": "Den beste måten å kontakte oss på er enten via diskordserverens live chat eller via e-post.",
  "title3": "Våre personvernregler",
  "p3_1": "Vi behandler din personlige informasjon for å gi brukerne våre tilgang til tjenestene på nettstedet vårt og for å drive vår virksomhet. Ved å godta vilkårene for bruk (og i noen land, ved å anerkjenne denne policyen), bekrefter du at du har lest og forstått denne policyen, inkludert hvordan og hvorfor vi bruker informasjonen din. Hvis du ikke vil at vi skal samle inn eller behandle din personlige informasjon på de måtene som er beskrevet i denne policyen, bør du ikke bruke tjenestene våre. Vi er ikke ansvarlige for innholdet eller personvernreglene eller praksisene til noen av våre medlemmer, nettsteder vi lenker til, eller tredjeparts nettsteder og apper.",
  "p3_2": "Våre vilkår for bruk krever at alle kontoeiere er minst 18 år. Mindreårige under 18 år har tillatelse til å bruke nettstedet og tjenestene våre bare hvis de har tillatelse, samtykke og tilsyn med og fra foreldrene eller foresatte. Du er ansvarlig for all kontoaktivitet som utføres av en mindreårig på deres konto. Vi “selger” ikke bevisst personopplysningene til mindreårige, ettersom det begrepet er definert i gjeldende lov.",
  "p3_3": "Ved å bruke dette nettstedet erkjenner du at vi vil bruke informasjonen din i landene hvor vi opererer. Vær oppmerksom på at personvernlovene og standardene i visse land, inkludert myndigheters rett til å få tilgang til din personlige informasjon, kan avvike fra de som gjelder i landet du bor i. Vi overfører bare personlig informasjon til de landene vi har lov til å overføre personlig informasjon til. Det er mer informasjon om dette i delen 'Datatilgang' i policyen.",
  "title4": "Informasjon som er samlet inn eller mottatt",
  "p4_1": "Når vi tilbyr tjenestene våre, samler vi inn eller mottar din personlige informasjon på noen få forskjellige måter. Ofte velger du hvilken informasjon du skal gi, men noen ganger krever vi viss informasjon for å gi deg våre tjenester. Vi bruker denne personlige informasjonen vi mottar og samler inn i samsvar med formålene beskrevet i denne kunngjøringen.",
  "p4_2": "Noen lenker på nettstedet kan føre deg til en tredjeparts nettsted. Vi har ikke ansvar for tredjeparts datainnsamlingspraksis, selv der de er lenket fra nettstedet vårt. Du bør forstå personvern- og sikkerhetspraksis for tredjepart før du deler informasjon med dem.",
  "p4_3": "Tilkobling: Når du kobler til nettstedet vårt, samles det inn data automatisk av nettstedet, for eksempel IP-adressen din. Disse dataene samles inn for å sikre en sikker forbindelse mellom enheten din og nettstedets servere, samt for å oppdage nettangrep. Det juridiske grunnlaget for denne innsamlingen og behandlingen er våre legitime interesser i henhold til artikkel 6 (1) (f) i GDPR.",
  "p4_4": "Registrering: Vi samler inn informasjon du gir oss når du registrerer deg, for eksempel for- og etternavn, brukernavn, kjønn, fødselsdato eller fødselsår og annen valgfri informasjon gitt til oss for å fullføre profilen din. Disse dataene oppbevares som en del av din bruk av tjenestene våre, og til ditt samtykke trekkes tilbake, med mindre annet er nødvendig for å forhindre misbruk av våre tjenester eller for å håndtere tvister. Det juridiske grunnlaget for denne databehandlingen er basert på ditt samtykke, i henhold til artikkel 6 (1) (a) i GDPR, og for annen informasjon, rettferdiggjort av våre legitime interesser i å administrere våre registrerte brukere, og å unngå etterligning eller annet misbruk av tjenester, i henhold til artikkel 6 nr. 1 bokstav f) i GDPR. Når du registrerer deg, bruker vi også øktcookies og / eller preferansecookies for midlertidig å lagre informasjon og for å holde deg logget inn. Denne databehandlingen er basert på våre legitime interesser, i samsvar med artikkel 6 (1) (f) i GDPR. Du kan administrere disse informasjonskapslene i nettleserinnstillingene.",
  "p4_5": "Klubb- og organisasjonsadministrasjon: For å sikre transparent klubb- og organisasjonsadministrasjon kan noe av din personlige informasjon, for eksempel brukernavnet ditt, og at informasjonen som finnes i FIDEs offentlige database være synlig for administratorene av klubbene eller organisasjonene du blir med via plattformen. Denne databehandlingen gjør det mulig for medlemsoppføringer og sammensetningen av klubber eller lag å være tydelig for disse administratorene, og behandles i samsvar med våre legitime interesser, i henhold til artikkel 6 (1) (f) i GDPR.",
  "p4_6": "Støtte: når du ber oss om å løse et teknisk problem eller andre administrative problemer, samler vi inn en viss mengde informasjon, for eksempel navnet ditt, e-postadressen, brukernavnet og omstendighetene i problemet. Vi kan også trenge å få tilgang til kontoen din for å se et problem eller iverksette tiltak for deg. Disse tilleggsdataene oppbevares til problemet er løst, og deretter i oppbevaringsperioden mens kontoen er aktiv eller for å håndtere mulige tvister. Denne behandlingen utføres i henhold til artikkel 6 (1) (f), våre legitime interesser for å støtte brukerne våre.",
  "p4_7": "Nettstedsforbedring: vi bruker data knyttet til levering av nettstedet vårt for å utvikle tjenestene våre på en slik måte at de er mer effektive for brukerne våre. Vi kan beholde noen anonymiserte data uansett, for eksempel navigering, opptak, engasjement og aktivitet av tjenestene våre. Vi beholder bare disse dataene så lenge det er nødvendig for analysen, slik at vi kan identifisere områder for å forbedre tjenestene våre. Denne behandlingen er berettiget i henhold til våre legitime interesser, i henhold til artikkel 6 (1) (f), slik at vi kan utvikle og forbedre tjenestene våre, og utvide sjakksporten.",
  "p4_8": "Rettssaker Avbøtelse: Vi vil oppbevare alle data der konservering av slike data synes å være nødvendige for å forhindre en tvist eller for å administrere og avbøte enhver rettssak eller forhåndstvist. Denne oppbevaringen er begrunnet med våre legitime interesser i å beskytte våre rettigheter og interesser, i samsvar med artikkel 6 (1) (f). Disse dataene blir lagret i den lovbestemte foreldelsesperioden.",
  "p4_9": "",
  "title5": "Informasjonsbruk, deling og avsløring",
  "p5_1": "Vi respekterer personvernet ditt. Vi vil ikke avsløre navnet ditt, e-postadressen eller annen personlig informasjon til tredjeparter uten ditt samtykke, bortsett fra som spesifisert i denne personvernerklæringen.",
  "p5_2": "Når du går inn på eller bruker nettstedet vårt, samler vi inn, bruker, deler og på annen måte behandler din personlige informasjon for de formål som er beskrevet i denne kunngjøringen. Vi er avhengige av en rekke juridiske grunnlag for å bruke informasjonen din på disse måtene. Disse juridiske grunnlag inkluderer:",
  "p5_l_1": "Du har gitt samtykke til behandlingen, som du kan tilbakekalle når som helst;",
  "p5_l_2": "Det er nødvendig å overholde en juridisk eller forskriftsmessig forpliktelse, en rettskjennelse, eller å utøve eller forsvare eventuelle forestående eller påståtte juridiske krav;",
  "p5_l_3": "Det er nødvendig med henblikk på vår eller en tredjeparts legitime interesser, som for eksempel partnerklubber, organisasjoner og føderasjoner;",
  "p5_l_4": "Du har uttrykkelig gjort informasjonen offentlig;",
  "p5_l_5": "Det er nødvendig i allmennhetens interesse, for eksempel for å forhindre kriminalitet;",
  "p5_l_6": "Hvis det er nødvendig å beskytte dine vitale interesser eller andres;",
  "p5_l_7": "Hvis det er nødvendig å utføre de kontraktsmessige forpliktelsene i våre tjenestevilkår og for å kunne tilby tjenestene våre til deg eller våre partnere.",
  "p5_3": "Vi stoler hovedsakelig på samtykke (som kan trekkes tilbake når som helst) i henhold til artikkel 6 (1) (a) i GDPR når en bruker registrerer seg for en konto hos oss. Noe av behandlingen vår blir også utført på grunnlag av legitime interesser, i henhold til artikkel 6 (1) (f) i GDPR, først og fremst for å tilby og forbedre tjenestene våre, ved å levere e-post som ikke er markedsført (som passordgjenoppretting), og som holder tjenestene våre trygge. og sikre, og andre juridiske og regulatoriske formål.",
  "title6": "Datatilgang",
  "p6_1": "Dataene dine hostes av en profesjonell tjenesteleverandør, basert i Frankrike. Vår primære server er basert i Frankrike, og sikkerhetskopieringsserveren er basert i Litauen. Alle servere er derfor lokalisert i EU, og det er ingen overføringer av personlig informasjon utenfor EU. Alle leverandører er lokalisert i EU, som er kjente og markedsledende tjenesteleverandører som er sertifisert i henhold til GDPR.",
  "title7": "Dine rettigheter",
  "p7_1": "I henhold til databeskyttelsesloven har du rettigheter vi trenger å gjøre deg oppmerksom på. Rettighetene som er tilgjengelige for deg, avhenger av vår grunn til å behandle informasjonen din.",
  "p7_l_1": "Rett til innsyn - du har rett til å be oss om kopier av din personlige informasjon. Denne retten gjelder alltid. Det er noen unntak, noe som betyr at du kanskje ikke alltid får informasjonen vi behandler.",
  "p7_l_2": "Rett til rettelse - du har rett til å be oss om å rette opp informasjon du mener er unøyaktig. Du har også rett til å be oss om å fullføre informasjon du mener er ufullstendig. Denne retten gjelder alltid.",
  "p7_l_3": "Rett til sletting - du har rett til å be oss om å slette din personlige informasjon under visse omstendigheter.",
  "p7_l_4": "Rett til å begrense behandlingen - du har rett til å be oss om å begrense behandlingen av informasjonen din under visse omstendigheter.",
  "p7_l_5": "Rett til å protestere mot behandling - du har rett til å motsette deg behandling hvis vi er i stand til å behandle informasjonen din fordi prosessen er en del av våre offentlige oppgaver, eller er i våre legitime interesser.",
  "p7_l_6": "Rett til dataportabilitet - du har rett til å be om at vi overfører informasjonen du har gitt oss fra en organisasjon til en annen, eller gir den til deg. Denne retten gjelder bare hvis vi behandler informasjon basert på ditt samtykke.",
  "p7_2": "Du er ikke pålagt å betale noe gebyr for å utøve dine rettigheter. Vi har en måned til å svare deg. Disse rettighetene gjelder bare innbyggere i EØS; Vi kan kreve ytterligere bekreftelse for å kunne bekrefte og fullføre forespørslene dine.",
  "title8": "Klager",
  "p8_1": "Vi jobber med høye standarder når det gjelder behandling av din personlige informasjon. Hvis du har spørsmål, kan du kontakte oss på hei@spillsjakk.no, så svarer vi.",
  "p8_2": "Hvis du forblir misfornøyd, kan du klage på måten vi behandler din personlige informasjon til din nasjonale databeskyttelsesmyndighet, eller til den kompetente myndigheten for databeskyttelse i Norge, nemlig Datatilsynet, via deres nettside datatilsnyet.no eller med post til følgende adresse: Datatilsynet, Postboks 458 Sentrum, NO-0105 Oslo.",
  "p8_3": "Denne retten kan utøves når som helst og medfører ingen kostnader for deg, bortsett fra kostnadene ved å sende posten (hvis aktuelt), og eventuelle kostnader for representasjon hvis du velger å søke hjelp fra en tredjepart."
}
