/* eslint-disable */
const no = {
  "403": "403 - Forbudt, du er ikke innlogget og har ingen tilgang.",
  "404": "404 - Ressursen er ikke tilgjengelig, hvis du mener dette er feil - sjekk om du er innlogget.",
  "500": "500 - Intern feil på serveren",
  "BETA": "BETA",
  "about": "Om",
  "aboutToPlay": "Partiet ditt starter snart!",
  "acceptDraw": "Aksepter remis?",
  "accountDoesNotExist": "Kontoen eksisterer ikke.",
  "accountHasNoEmail": "Ingen epostadresse er tilknyttet kontoen.",
  "accountList": "Kontoliste",
  "accountSettings": "Kontoinnstillinger",
  "add": "Legg til",
  "addATeam": "Legg til et lag",
  "addClub": "Legg til en klubb",
  "addClubId": "Legg til klubb-ID",
  "addMember": "Legg til et medlem",
  "addOtherAccount": "Legg til en konto",
  "addPairing": "Legg til partioppsett",
  "addParticipantsWithAccount": "Legg til deltakere med konto",
  "addParticipantsWithoutAccount": "Legg til deltakere uten konto",
  "addPlayerId": "Legg til spiller-ID",
  "addRound": "Legg til en runde",
  "age": "Alder",
  "allowSelfJoining": "Tillat at spillerne melder seg på turneringen selv.",
  "alreadyHaveAccount": "Har du allerede en konto på SpillSjakk?",
  "alreadyMemberOfTeam": "Kontoen er allerede registrert i dette laget.",
  "areYouAtSpillsjakkDiscord": "Er du i SpillSjakk sin Discord-server?",
  "averageAge": "Gjennomsnittsalder",
  "averageOpponentRating": "Gjennomsnittlig ratingmotstand",
  "backToTournament": "Tilbake til turneringen",
  "banner_picture": "Banner-bilde",
  "birthDate": "Fødselsdato",
  "black": "Svart",
  "blackWins": "Svart vinner 0-1",
  "blackWon": "Seier til svart",
  "board": "Brett",
  "boardCards": "Bordkort",
  "browse": "Søk",
  "browseClubs": "Søk i klubber",
  "browseOrgs": "Søk i organisasjoner",
  "browseOrgsAndClubs": "Søk i organisasjoner og klubber",
  "buchholz": "FIDE Buchholz, ingen strykninger",
  "buchholzCut1": "FIDE Buchholz Cut 1, dårligste strøket",
  "buchholzCut2": "FIDE Buchholz Cut 2, to dårligste strøket",
  "buildAndInvite": "Opprett turnering og inviter spillere",
  "buildTournament": "Opprett turnering",
  "bulkAccountCreation": "Opprett flere kontoer",
  "byRating": "Etter rating",
  "carousel1": "Turneringsprogram med online spillplattform integrert",
  "carousel1Desc": "Enkel organisering av individuelle turneringer, lagturneringer, seriespill og hybridturneringer online og/eller over brettet. Vi har allerede Sveitser, Berger, Knockout individuelt og for lag samt manuelt oppsett med flere",
  "carousel2": "Til forskjellige bruksområder",
  "carousel2Desc": "Et fleksibelt og brukervennlig verktøy tilpasset flere prosjekter og aktiviteter",
  "carousel3": "SpillSjakk tilhører sjakkspillerne",
  "carousel3Desc": "Gjennom forbundene er det norske sjakkspillere som eier SpillSjakk. Det betyr at SpillSjakk alltid vil sette de aktive sjakkspillernes behov først",
  "carousel4": "Bli med og påvirk!",
  "carousel4Desc": "Bli med i SpillSjakk sin Discord-server for de siste oppdateringene fra utviklerteamet. Delta i test-turneringer, kom med ris, ros og bidra med forslag slik at SpillSjakk blir best mulig!",
  "changeEmail": "Endre epost",
  "changeNextPairingDateTime": "Endre tidspunkt for neste rundeoppsett",
  "changePassword": "Endre passord",
  "clickToCopy": "Trykk for å kopiere",
  "clubCount": "Antall klubber",
  "clubInvitations": "Klubb-invitasjoner",
  "clubYouHaveBeenInvited": "Du er invitert av $name til å legge til din klubb på SpillSjakk. Opprett konto raskt og enkelt for å la spillerne i klubben delta i større turneringer. I tillegg kan klubben organisere interne turneringer på nett og over brett.",
  "clubs": "Klubber",
  "confirmTournamentDeletion": "Vil du virkelig slette turneringen?",
  "confirmTournamentWithVisibleNames": "Brukernes navn blir synlig i turneringen, hvis du ikke ønsker det velger du",
  "congrats": "Gratulerer",
  "contact": "Kontakt",
  "continue": "Fortsett",
  "continueWithoutTemplate": "Fortsett uten å velge mal",
  "copied": "Kopiert",
  "country": "Land",
  "create": "Opprett",
  "createATeam": "Opprett et lag",
  "createAccounts": "Opprett kontoer",
  "createAccount": "Opprett konto",
  "createYourAccount": "Opprett din konto på SpillSjakk",
  "createYourClub": "Opprett klubben din på SpillSjakk",
  "currentPassword": "Nåværende passord",
  "custom": "Redigere",
  "dateTime": "Dato og tid",
  "days": "dager",
  "defaultGameLocation": "Standard plassering",
  "deletePairing": "Slett rundeoppsettet",
  "deleteRound": "Slett runden",
  "deleteTournament": "Slett turneringen",
  "description": "Beskrivelse",
  "downloadAsCsv": "Last ned som CSV",
  "draw": "Partiet ble remis",
  "drawOfferPending": "Remistilbud",
  "drawPoints": "Poeng for remis",
  "editTournament": "Rediger turneringsdetaljer",
  "email": "Epost",
  "emailChanged": "Epostkontoen er endret.",
  "endDate": "Sluttdato",
  "expandAll": "Utvid alle",
  "featuresWeOffer": "FUNKSJONER VI TILBYR",
  "federation": "Forbund",
  "fideFederation": "Verdens Sjakkforbund - FIDE",
  "fideprofil": "FIDE Profil",
  "fideInfoText": "Legg til FIDE-ID-en din hvis du har en, hvis ikke, trykk neste ",
  "fideNumber": "FIDE-nummer",
  "fideRated": "Dette er en FIDE-ratet turnering.",
  "fideRating": "FIDE-rating",
  "fillDateAndTime": "Sett inn dato og tid for runden",
  "findTournament": "Finn en turnering",
  "findTournaments": "Finn turneringer",
  "findoutFide": "Hvordan finner jeg ut om jeg har FIDE-ID?",
  "finished": "Ferdig",
  "firstName": "Fornavn",
  "firstPairingDateTime": "Tid og dato for første rundeoppsett",
  "flaggingTime": "Flagging time!",
  "forgotPassword": "Har du glemt passordet?",
  "fullName": "Fullt navn",
  "game": "Parti",
  "gameLocationCanBeChanged": "Plassering kan endres underveis",
  "gameScore": "Partiresultat",
  "games": "Partier",
  "gender": "Kjønn",
  "generate-next-round": "Generer neste runde",
  "getInTouch": "TA KONTAKT!",
  "goToLobby": "Gå til lobbyen",
  "goodGame": "Bra parti",
  "greatEndgame": "Bra teknikk i sluttspillet",
  "hello": "Hei",
  "home": "Hjem",
  "hours": "timer",
  "iDidntNeedThatPiece": "Jeg trengte ikke den brikken likevel",
  "iLikeYourOpening": "Liker åpningen din",
  "iNeedtoRethink": "Jeg trenger å revurdere min tilnærming til sjakk",
  "id": "ID",
  "idAlreadyTaken": "ID-en er opptatt - allerede i bruk.",
  "ifNoOnlineGames": "Hvis det spilles kun over brettet kan du angi hvilken som helst dato og tid",
  "inbox": "Innboks",
  "incorrectPassword": "Ugyldig passord",
  "individual": "Individuell",
  "invalidCredentials": "Feil brukernavn og/eller passord.",
  "invalidPasswordRecoveryCode": "Koden for gjenoppretting av passord er ugylding.",
  "invite": "Invitere",
  "itsADraw": "Remis 1/2 - 1/2",
  "join": "Bli med",
  "joinFor": "Meld deg på turneringen for",
  "joinOrganization": "Bli med i en organisasjon",
  "joinTournament": "Bli med i en turnering",
  "knockout": "Knockout",
  "lastName": "Etternavn",
  "leave": "Gå ut",
  "leaveTournament": "Gå ut av turneringen",
  "letsPlayAgain": "La oss spille igjen en gang",
  "liveAndUpcomingTournaments": "PÅGÅENDE OG KOMMENDE TURNERINGER",
  "lobby": "Lobby",
  "localTime": "Lokal tid",
  "login": "Logg inn",
  "loginAndTryAgain": "Logg inn og prøv igjen",
  "loginFirst": "Logg inn først",
  "logout": "Logg ut",
  "lossPoints": "Poeng ved tap",
  "manage": "Administrer",
  "manageLeague": "Administrer Ligaen",
  "manageClub_clubsManager": "Klubbens Manager",
  "manageClub": "Administrer klubb",
  "manageClub_aboutClub": "Om klubben:",
  "manageClub_aboutClubDesc": "Informasjonen er åpen for alle og du kan promotere klubben din her.",
  "manageClub_accounts": "Kontoer i klubben",
  "manageClub_addNewTeam": "nytt lag",
  "manageClub_address": "Adresse:",
  "manageClub_addressDesc": "(Adressen til sjakklubben blir delt på klubbens profilside)",
  "manageClub_bannerPhoto": "Banner bilde",
  "manageClub_bannerPhotoDesc": "Dette er et større bilde som vises på profilsiden til klubben - har du en sponsor eller noe annet du vil profilere? Lim inn URL-en til et bilde i jpg eller png under. ",
  "manageClub_clubsId": "Klubbens URL (SpilSjakk-ID)",
  "manageClub_clubsIdDesc": "(Dette feltet vil vises mot slutten av URL-en til SpillSjakk, f.eks. spillsjakk.no/club/view/CUSTOMURL)",
  "manageClub_clubsName": "Klubbens navn",
  "manageClub_clubsNameDesc": "Dette navnet vil bli offentlig synlig",
  "manageClub_email": "Epost",
  "manageClub_emailDesc": "en offentlig epostadresse som kan benyttes til å kontakte din klubb",
  "manageClub_profilePhoto": "Profilbilde",
  "manageClub_profilePhotoDesc": "dette bør være et merke eller en logo - det vil vises på turneringssidene du oppretter på SpillSjakk, og på klubbens profilside. Lim inn URL-en til et bilde i jpg eller png like under",
  "manageClub_teamCaptainPackage": "Lagleder",
  "manageClub_teamCaptainPackageDesc": "kan sende meldinger til medlemmer og flytte spillere fra en klubb til et lag",
  "manageClub_teamsInClub": "Lagene i klubben",
  "manageClub_totalPlayerCount": "Antall spillere totalt",
  "manageClub_totalTeamCount": "Antall lag totalt",
  "manageClub_website": "Websider og sosiale media",
  "manageClub_websiteDesc": "(hjemmeside, twitterkonto, facebookside og eventuelt andre sider, vil bli delt på klubbprofilen)",
  "manageOrg_aboutOrg": "Om organisasjonen",
  "manageOrg_address": "Adresse",
  "manageOrg_addressDesc": "(Adressen til organisasjonen din blir delt på din profilside)",
  "manageOrg_allPowersPackage": "Alle tilganger",
  "manageOrg_allPowersPackageDesc": "(denne brukeren får tilsvarende rettigheter som administrator i en organisasjon)",
  "manageOrg_arbiterPowersPackage": "Tilgang som dommer",
  "manageOrg_arbiterPowersPackageDesc": "(denne brukeren får admin-rettigheter som en dommer i en organisasjon, og kan opprette turneringer",
  "manageOrg_bannerPhoto": "Banner-bilde",
  "manageOrg_bannerPhotoDesc": "Dette er et større bilde som vises på profilsiden til organisasjonen - har du en sponsor eller noe annet du vil profilere? Kopier og lim inn URL-en til et bilde (jpg eller png)like under.",
  "manageOrg_editorPowersPackage": "Tilgang for redigering av tekst:",
  "manageOrg_editorPowersPackageDesc": "(denne brukeren vil kunne redigere alle tekstfelt på samme måte som administrator av en organisasjon)",
  "manageOrg_email": "Epost",
  "manageOrg_emailDesc": "en offentlig epostadresse som kan benyttes til  å kontakte din organisasjon)",
  "manageOrg_orgsAccounts": "Kontoer i organisasjonen",
  "manageOrg_orgsClubs": "Klubber i organisasjonen",
  "manageOrg_orgsDesc": "Om organisasjonen",
  "manageOrg_orgsDescDesc": "Dette er åpent for alle og du kan informere om klubben din her.",
  "manageOrg_orgsId": "Organisasjonens URL (SpillSjakk-ID)",
  "manageOrg_orgsIdDesc": "(dette feltet vil vises mot slutten av SpillSjakk`s URL, f.eks. spillsjakk.no/organization/view/CUSTOMURL)",
  "manageOrg_orgsName": "Organisasjonens navn",
  "manageOrg_orgsNameDesc": "(dette navnet vil være offentlig synlig",
  "manageOrg_profilePhoto": "Profilbilde",
  "manageOrg_profilePhotoDesc": "dette bør være et merke eller en logo - det vil vises på turneringssidene du oppretter på SpillSjakk, og på din profilside. Lim inn URL-en til et bilde i jpg eller png like under",
  "manageOrg_sharePower": "Gi rettigheter til en konto",
  "manageOrg_totalClubCount": "Antall klubber totalt:",
  "manageOrg_totalPlayerCount": "Antall spillere totalt:",
  "manageOrg_website": "Hjemmesider og sosiale media:",
  "manageOrg_websiteDesc": "(hjemmeside, twitterkonto, facebookside og eventuelt andre sider, vil bli delt på organisasjonsprofilen) ",
  "manageOrganization": "Administrer organisasjonen",
  "manageParticipants": "Organiser deltakere",
  "manageRoundsAndPairings": "Organiser rundeoppsett",
  "manageTeamPlayers": "Organiser spillere i laget",
  "manageTournament": "Organiser turneringen",
  "manualPairing": "Manuelt oppsett",
  "matchScore": "Kampresultat",
  "medianBuchholz": "Median Buchholz, dårligste og beste strøket",
  "medianBuchholz2": " Median Buchholz 2, to dårligste og to beste strøket",
  "members": "Medlemmer",
  "membersPerTeam": "Spillere per lag",
  "messageJustToTeamMembers": "Send melding til medlemmer i laget",
  "messageMembers": "Send melding til medlemmer",
  "mfRatio": "M/F ratio",
  "minutes": "minutter",
  "mouseSlip": "Argh! Mouseslip",
  "myTeams": "Mine lag",
  "myTournamentCalendar": "Min turneringskalender",
  "name": "Navn",
  "nameOfThePlayer": "Spillerens navn eller FIDE-ID",
  "namePrivacyInfo": "Ditt virkelige navn kan vises i noen turneringer. Du vil få beskjed om dette når du melder deg på en turnering. Når bare brukernavn er synlig er det kun admininistrarorer i klubben eller organisasjonen som vil se virkelige navn. For mer informasjon, les vår $personvernerklæring.",
  "navbarAccountCreation": "OPPRETT KONTO",
  "navbarInfo": "INFO",
  "navbarLogin": "LOGG INN",
  "navbarOrgs": "ORGANISASJONER",
  "navbarTournaments": "TURNERINGER",
  "newPassword": "Nytt passord",
  "newPasswordAgain": "Gjenta nytt passord",
  "next": "Neste",
  "nextGame": "Neste parti",
  "nextGameIn": "Neste parti starter om",
  "nextOnlinePairingWillBeAt": "Neste online rundeoppsett kommer",
  "nextRoundText": "Neste runde starter om:",
  "niceTactic": "Glimrende taktikk",
  "no": "Nei",
  "noUpcomingGames": "Det er ikke satt opp noen partier for deg enda.",
  "notDisplayedPublicly": "Dette vises ikke offentlig",
  "notFound": "Ikke funnet",
  "notParticipating": "Deltar ikke",
  "numberOfRounds": "Antall runder",
  "off": "av",
  "offerDraw": "Tilby remis",
  "on": "på",
  "oneClickJoin": "Trykk en gang for å bli med",
  "ongoing": "Pågående",
  "ongoingTournaments": "Pågående turneringer",
  "online": "Online",
  "onlinePairingInterval": "Intervall mellom runder online",
  "onlyShowScoresOfTop": "Velg antall spillere eller lag som skal vises med resultat på listene",
  "opponent": "Motspiller",
  "organiser": "Arrangør",
  "organizationInvitations": "Invitasjoner fra organisasjonen",
  "organizationStatistics": "Statistikk om organisasjonoen",
  "organizations": "Organisasjoner",
  "otb": "Over brettet",
  "otherClubMembers": "Andre klubbmedlemmer",
  "pageNotFound": "Siden ble ikke funnet",
  "pairingPrintouts": "Utskrift av rundeoppsett",
  "pairings": "Rundeoppsett",
  "participant": "Deltaker",
  "participants": "Deltakere",
  "participating": "Deltar",
  "password": "Passord",
  "passwordChanged": "Passord har blitt endret",
  "passwordRecoveryEmailSent": "Epost med lenke til gjenoppretting av passord er sendt til deg.",
  "passwords": "Passord",
  "passwordsAreNotEqual": "Passordene er ikke like",
  "permissions": "Tillatelser",
  "play": "Spill",
  "playGame": "Spill parti",
  "player": "Spiller",
  "playerCount": "Antall spillere",
  "players": "Spillere",
  "playersPerTeam": "Antall spillere per lag",
  "pleaseSelect": "Velg",
  "privacyPolicy": "Personvernerklæring",
  "privateTournament": "Dette er en privat turnering.",
  "productDescription": "Enkel og sikker turneringsadministrasjon.",
  "productName": "SpillSjakk",
  "profilePicturePlaceholder": "Sett inn URL-en til bildet",
  "profile_picture": "Profilbilde",
  "provisionalFideRating": "Midlertidig FIDE-rating",
  "publiclyViewable": "Offentlig synlig",
  "random": "Tilfeldig",
  "rating": "Rating",
  "rawPgn": "PGN",
  "recover": "Gjenopprett",
  "recoverAccount": "Gjenopprett konto",
  "region": "Region",
  "remove": "Fjerne",
  "resign": "Gi opp",
  "result": "Resultat",
  "resultPrintouts": "Utskrift av resultatliste",
  "reviewGame": "Se på partiet",
  "round": "Runde",
  "roundNb": "Antall runder",
  "roundRobin": "Berger",
  "rounds": "runder",
  "saveUpdates": "Lagre oppdateringer",
  "savedtournament": "Lagrede turneringsmaler",
  "score": "Resultater",
  "searchByFideId": "Søk etter FIDE-ID",
  "searchEllipsis": "Søk...",
  "searchTournaments": "Søk etter turneringer",
  "seconds": "sekunder",
  "seed": "Rangere",
  "seeding": "Rangering",
  "send": " Send",
  "setup": "Oppsett",
  "setupRound": "Sett opp runde",
  "sex": "Kjønn",
  "showOnlyUsernames": "Vis kun brukernavnet til spillerne på turneringssidene",
  "spectate": "Se på",
  "standings": "Oversikt",
  "start": "Start",
  "startDate": "Startdato",
  "startTyping": "Begynn å skrive eller velg",
  "startsIn": "Starter om:",
  "statistics": "Statistikk",
  "submit": "Send inn",
  "suggestedAccounts": "Foreslåtte kontoer",
  "suggestedOrganizations": "Foreslåtte organisasjoner",
  "swissDutch": "Sveitser",
  "team": "Lag",
  "teamKnockout": "Knockout for lag",
  "teamKonrad": "Konrad for lag",
  "teamManager": "Lagleder",
  "teamMonrad": "SkoleSjakken",
  "teamOlympiad": "Olympisk for lag",
  "teamRoundRobin": "Berger for lag",
  "teamSwissDutch": "Sveitser for lag",
  "teams": "Lag",
  "thankYouForGame": "Takk for partiet",
  "theUrlForThisTeam": "URL-en for dette laget blir /team/view/<id>",
  "theUrlForThisTournament": "URL-en  til denne turneringen blir /tournament/view/<id>",
  "thisIsTheOnlyTimeYouSeeThesePasswords": "Det er kun nå du ser passord og brukernavn til kontoen(e)!",
  "tiebreaker": "Rangering",
  "timeControl": "Tidskontroll",
  "title": "Tittel",
  "toMakeFirstMove": "til å gjøre det første trekket",
  "totalClubCount": "Antall klubber totalt",
  "totalPlayerCount": "Antall spillere totalt",
  "tournament": "Turnering",
  "tournamentHomepage": "Turneringens hjemmeside",
  "tournamentName": "Turneringsnavn",
  "tournamentOrganizer": "Turneringsleder",
  "tournaments": "Turneringer",
  "type": "Type",
  "upcomingTournaments": "Kommende turneringer",
  "update": "Oppdater",
  "userGuide": "Brukerhåndbok",
  "username": "Brukernavn",
  "usernameExists": "Brukernavnet finnes allerede, velg et nytt og prøv igjen",
  "viewGame": "Se parti",
  "waitingForGame": " Vent på det neste partiet",
  "weeks": "uker",
  "weighted": "Vektet",
  "wellPlayed": "Bra spilt",
  "whatIsFide": "Hva er FIDE-rating?",
  "white": "Hvit",
  "whiteWins": "Hvit vinner, 1-0",
  "whiteWon": "Seier til hvit",
  "whyFide": "Hvorfor trenger SpillSjakk min FIDE-ID?",
  "winPoints": "Poeng for seier",
  "yes": "Ja",
  "you": "Du",
  "youHaveBeenInvited": "Du har blitt invitert til å bli med for $name på SpillSjakk. Opprett konto raskt og enkelt for å kunne spille turneringer!",
  "nextGameIsIn": "Ditt neste parti starter om",
  "goToTournamentDetail": "Se turneringsinformasjonen",
  "tournamentSchedule": "Turneringsplan",
  "recentTournament": "Nylig ferdigspilt turnering",
  "list": "Liste",
  "miniboards": "Minibrett",
  "createOrganization": "Opprett en organisasjon",
  "createOrg_name": "Organisasjonens navn",
  "createOrg_nameDesc": "(dette navnet vil bli offentlig synlig",
  "createOrg_id": "Organisasjonens URL (SpillSjakk ID)",
  "createOrg_idDesc": "(dette feltet vil vises til slutt i nettadressene på SpillSjakk, f.eks. spillsjakk.no/organization/view/TILPASSEURL)",
  "createOrg_desc": "Om organisasjonen",
  "createOrg_descDesc": "(dette er offentlig synlig og er en mulighet til å fortelle publikum om organisasjonen din)",
  "createOrg_manager": "Organisasjonsadmin",
  "createOrg_managerDesc": "Bruker-ID for kontoen som skal administrere organisasjonen",
  "tournamentHistory": "Turneringshistorikk",
  "gameHistory": "Partihistorikk",
  "memberships": "Medlemskap og tilknytning",
  "ok": "Ok",
  "userIconHelpbox": "Dette viser at du er riktig innlogget. Du kan klikke på ikonet for å få tilgang til profilsiden, meldingsinnboksen og for å logge av.",
  "logoHelpbox": "Hvis du går deg bort, kan du klikke på denne logoen for å komme tilbake til hjemmesiden",
  "homeTournamentsHelpbox": "Offentlige turneringer og turneringer som er tilgjengelige for deg å spille i, vises her",
  "userProfileTournamentHelpbox": "Dette er en oversikt over de offentlige partiene og turneringene du deltatt i",
  "userProfileAccountSettingsHelpbox": "Du kan klikke på denne lenken for å redigere eller endre e-postadressen og passordet ditt",
  "createAccountsActionHelpbox": "Dette er den manuelle metoden for å opprette kontoer som gir full kontroll over spillerdetaljer. Hvis du trenger å lage mange spillerkontoer til spillere du stoler på, kan du prøve å dele klubbens unike invitasjonslenke med dine potensielle spillere under 'Kontoopprettelse -> Inviter'",
  "createAccountsInputsHelpbox": "Brukernavn vises offentlig, så vær sikker på at de er passende. Bare du og organisasjoner klubben din tilhører vil se det virkelige navnet på spillerne, bortsett fra i visse turneringer. Alle andre felt kan stå tomme, unntatt fødselsdato og kjønn, men disse er bare for journalføring og trenger ikke være nøyaktige. Hvis du har spillerens FIDE ID, kan du spare tid ved å angi nummeret først, og de andre relevante feltene vil fylles automatisk",
  "generateInvitationLinkHelpbox": "Dette er den automatiske metoden for å opprette kontoer. Når den unike URL-en deles med potensielle spillere, kan de følge den for å legge inn egne detaljer. Dette er den beste måten å raskt lage mange kontoer på, men hvis du trenger mer begrenset tilgang eller kontroll over prosessen, kan det være bedre å bruke manuell kontooppretting under 'Kontooppretting -> Opprett kontoer'.",
  "invitationLinkDescriptionHelpbox": "Vær oppmerksom på at alle med denne lenken kan opprette en konto og være en del av klubben eller organisasjonen din. Du kan imidlertid fjerne uønskede kontoer via kontrollpanelet for klubben eller organisasjonen.",
  "clubManageDetailHelpbox": "Dette viser informasjon om klubben, hvor mye kan redigeres.",
  "clubManageAccountsHelpbox": "Trykk på utvid alle for å se alle kontoene i klubben. Du kan fjerne en konto fra klubben ved å trykke på det røde krysset ved siden av navnet. Hvis du kjenner spiller-ID-en til en konto, kan du legge dem direkte til klubben.",
  "clubManageMessageHelpbox": "Du kan sende en melding til alle medlemmene i klubben - meldingen går til SpillSjakk-innboksen. Du kan også sende meldinger til bare et bestemt lag",
  "clubManageSharePowerHelpbox": "Noen av klubbens admin-rettigheter kan deles - du bør bare gjøre dette med folk du absolutt stoler på, for eksempel styremedlemmer i klubben eller andre personer med ansvar. Bare skriv inn spiller-ID-en, legg til admin-rettigheter og trykk på den grønne ruten. Denne kontoen vil deretter motta ytterligere admin-rettigheter",
  "clubManageStatsHelpbox": "Dette viser antall lag og spillere - mer statistikk vil bli lagt til her i fremtiden",
  "clubManageTeamsHelpbox": "En klubb kan deles i lag. En spiller vil alltid tilhøre en klubb, men kan også tilhøre ett eller flere lag. Skriv inn lagets navn, og trykk på den grønne ruten for å opprette laget. Deretter kan du klikke på lagnavnet du har opprettet for å legge til og/eller fjerne spillere i laget. Ved å klikke på det røde krysset ved siden av slettes laget.",
  "tournamentDetailManageTournamentHelpbox": "Som arrangør av denne turneringen kan du klikke her for å få tilgang til flere admin-rettigheter. Bare du, eller kontoer med de tilsvarende rettigheter du har gitt dem, kan se dette.",
  "manageTournamentManageParticipantsHelpbox": "Legg til spillergruppene du vil gi tilgang til turneringen her. Hvis egenpåmelding er slått på, vil de se en knapp på turneringssiden som lar dem melde seg på turneringen. Du kan også velge å legge til alle spillerne som skal delta manuelt.",
  "manageTournamentChangeTimeHelpbox": "Du kan endre tidspunktet for neste rundeoppsett i løpet av en runde eller x minutter før den er planlagt å starte.",
  "manageTournamentStandingsHelpbox": "Etter at turneringen starter og resultatene kommer inn, vil du også se oppsettet her. Du kan endre resultatet av et spill manuelt ved å klikke på poengsummen som skulle vært gitt.",
  "tournamentBuildBannerDesc": "for beste resultat, velg et bilde som er 4 ganger bredere enn det er høyt",
  "format": "Format",
  "howToMakeAccount": "Hvordan kan jeg opprette en konto?",
  "howToMakeAccountDesc": "SpillSjakk er fortsatt under utvikling, større oppdateringer skjer hyppig. Midlertidige kontoer blir gitt til utvalgte skoler, sjakklubber og prosjekter i regi av NSF/USF.  Per nå er det kun disse som kan tildele spillerkontoer på plattformen.",
  "clubProfile": "Klubbprofil",
  "organizationProfile": "Organisasjonsprofil",
  "tournamentCalendar": "turneringer",
  "signup": "meld deg på",
  "readMore": "les mer",
  "homev2Desc": "Et effektivt pairing- og spilleverktøy for klubber og turneringsarrangører. SpillSjakk støtter nettsjakk, bordsjakk og hybridturneringer.",
  "onSpillsjakk": "på SpillSjakk",
  "editAccount": "administrer konto",
  "edit": "Administrer",
  "teamSize": "Klassestørrelse",
  "invitePairPlay": "Inviter - Sett opp - Spill",
  "soon": "snart",
  "later": "senere",
  "today": "i dag",
  "realWorld": "Bordsjakk",
  "back": "Tilbake",
  "customSettings": "Sett opp manuelt",
  "buildTournament_chooseTemplateDesc": "Velg en ferdig mal eller sett opp manuelt.",
  "buildTournament_timeAllowance": "Starttid per spiller per parti",
  "buildTournament_timeAdded": "Tillegg i tid per trekk",
  "buildTournament_intervalGames": "Intervall mellom rundene",
  "buildTournament_templateSelection": "Valg av innstillinger",
  "buildTournament_location": "Online eller brettsjakk?",
  "buildTournament_reviewAll": "Gjennomgang",
  "buildTournament_useTemplate": "Tilbake",
  "buildTournament_privacyAdvanced": "Personvern og avanserte innstillinger",
  "buildTournament_startDateTime": "Startdato og starttid",
  "buildTournament_format": "Format",
  "buildTournament_timeControl": "Tidskontroll",
  "buildTournament_pleaseSelectOrganiser": "Velg organisator",
  "moreInfo": "informasjon",
  "rank": "Rangering",
  "nextWithTemplate": "Gå videre med valgt mal",
  "accountsWithPowers": "Kontoer med rettigheter",
  "buildLeague": "Opprett en liga",
  "leagueHomepage": "Ligaens hjemmeside",
  "createSeason": "Opprett en sesong",
  "createCategory": "Opprett en kategori",
  "crossTable": "Krysstabell",
  "genderRestricted": "Begrensning kjønn",
  "ageRestricted": "Begrensning alder",
  "ratingRestricted": "Begrensning rating",
  "minimumAge": "Minimumsalder",
  "maximumAge": "Maksimal alder",
  "minimumRating": "Minimum rating",
  "maximumRating": "Maksimal rating",
  "downloadPgns": "Last ned PGN",
  "leagueManagePage": "Administrer ligaen",
  "categories": "Kategorier",
  "seasons": "Sesonger",
  "addCategory": "Legg til kategori",
  "addSeason": "Legg til sesong",
  "editLeague": "Rediger liga",
  "seasonAndCategory": "Sesong og kategori",
  "league": "Liga",
  "messageAllClubMembers": "Send melding til alle klubbmedlemmer",
  "userId": "Bruker-ID",
  "addTeams": "Legg til lag",
  "alreadyAMember": "Du er allerede medlem",
  "delete": "Slett",
  "pairingGenerationFailed": "Denne turneringen kunne ikke fullføres på grunn av for få spillere. Det er ikke mulig å finne et gyldig oppsett.",
  "chatEnabled": "Chatfunksjonen er aktivert",
  "stats": "Statistikk",
  "statsNumberOfPlayers": "Antall spillere",
  "statsTitledPlayers": "Antall tittelspillere",
  "statsCountries": "Antall land/spillere per land som deltar",
  "statsAverageRating": "Gjennomsnittlig rating",
  "statsLongestGame": "Lengste parti",
  "statsShortestGame": "Korteste parti",
  "filterByClub": "Sorter etter klubb",
  "lichessUsername": "Brukernavn på Lichess",
  "linkLichessAccount": "Brukerkonto på Lichess",
  "end": "Slutt",
  "promotion": "Opprykk",
  "relegation": "Nedrykk",
  "promote": "Opprykk",
  "relegate": "Nedrykk",
  "pleaseSelectPlayer": "Velg spiller",
  "pleaseSelectTeam": "Velg lag",
  "pleaseSelectCategory": "Velg kategori",
  "successfull": "Vellykket",
  "error": "Feil",
  "endSeasonAndStartNewOne": "Avslutt inneværende sesong og sett opp ny",
  "startSeason": "Start sesongen",
  "saveAndCreate": "Lagre og opprett",
  "manageTeam": "Administrer laget",
  "removeInactive": "Fjern inaktive spillere",
  "selectClub": "Velge Klubb",
  "print": "Utskrift",
  "boardNumber": "Bordnummer",
  "printoutPage": "Utskrift",
  "tomorrow": "i morgen",
  "updatePinSuccessfulMessage": "Turneringsstatusen er oppdatert",
  "pinnedOrder": "Ordre",
  "pinned": "Høydepunkter",
  "tournamentTemplatePlaceholder": "Velg mal",
  "increment": "tillegg",
  "leagues": "Ligaer",
  "chess": "Chess",
  "antichess": "Antichess",
  "pawnVsPawn": "Pawn vs Pawn",
  "limitedPlayerTeam": "Four Player Team"
};

export { no };
