/* eslint-disable */
export default {
  "title1": "Privacy Notice",
  "p1_1": "This privacy notice tells you what to expect us to do with your personal information when you make contact with us, register an account with us, or use one of our services.",
  "p1_2": "This notice is indexed. So, if you wish, you can easily select the reason we process your personal information and see what we do with it.",
  "p1_3": "This notice does not apply to the practices of third parties, who may also collect or receive data in connection with your use of our website.",
  "title2": "Data Controller’s Details",
  "p2_1": "Spillsjakk.no is the controller for the personal information we process, unless otherwise stated.",
  "p2_2": "The best way to contact us is either through our discord server’s live chat, or by email.",
  "title3": "Our Privacy Policy",
  "p3_1": "We process your personal information to provide our users with access to the services on our website, and to run our business. By accepting our Terms of Service (and in some countries, by acknowledging this policy), you confirm that you have read and understand this policy, including how and why we use your information. If you don’t want us to collect or process your personal information in the ways described in this policy, you shouldn’t use our services. We are not responsible for the content or the privacy policies or practices of any of our members, websites we link to, or third-party websites and apps.",
  "p3_2": "Our Terms of Service require all account owners to be at least 18 years of age. Minors under 18 years of age are permitted to use our website and services only if they have permission, consent, and supervision of and from their parent or legal guardian. You are responsible for any and all account activity conducted by a minor on their account. We do not knowingly “sell”, as that term is defined under applicable law, the personal information of minors.",
  "p3_3": "By using this website, you acknowledge that we will use your information in the countries where we operate. Please be aware that the privacy laws and standards in certain countries, including the rights of authorities to access your personal information, may differ from those that apply in the country in which you reside. We will transfer personal information only to those countries to which we are permitted by law to transfer personal information. There is more information on this in the “Data access” section of the policy.",
  "title4": "Information Collected or Received",
  "p4_1": "In the course of providing our services, we collect or receive your personal information in a few different ways. Often, you choose what information to provide, but sometimes we require certain information to provide you our services. We use this personal information we receive and collect in accordance with the purposes described in this notice. ",
  "p4_2": "Some links on the website may lead you to a third party’s website. We do not have responsibility for the data collection practices of any third party, even where they are linked from our site. You should understand the privacy and security practices of any third party before you share information with them.",
  "p4_3": "Connection: when you connect to our site some data is automatically collected by the website, such as your IP address. This data is collected for the purpose of ensuring a secure connection between your device and the website’s servers, as well as to detect cyberattacks. The legal basis for this collection and processing is our legitimate interests per Article 6(1)(f) of the GDPR.",
  "p4_4": "Registration: we collect information you provide to us when registering, such as first and last name, a username, gender, a date of birth or birth year, and other optional information provided to us to complete your profile. This data is kept as part of your use of our services, and until your consent is withdrawn, unless otherwise needed to prevent abuse of our services or to handle disputes. The legal basis for this data processing is based on your consent, per Article 6(1)(a) of the GDPR, and for other information, justified by our legitimate interests in managing our registered users, and avoiding impersonation or other service abuses, as per Article 6(1)(f) of the GDPR. When registering, we also use session cookies and/or preference cookies to temporarily store information and to keep you logged in. This data processing is based on our legitimate interests, in accordance with Article 6(1)(f) of the GDPR. You can manage these cookies in your browser settings.",
  "p4_5": "Tournament Administration: in order to ensure transparent competitions, result lists, pairings and standings, we collect a certain amount of information, such as your username, real name, and FIDE ID. Some tournaments will only publicly disclose your username, others will disclose more information publicly; these will be notified to you to enable you to make an active choice. This data processing’s legal basis is justified per Article 6(1)(f), our legitimate interests in providing correct tournament pairings, result lists, and records of that information.",
  "p4_6": "Club and Organisation Administration: in order to ensure transparent club and organisation administration, some of your personal information such as your username, and that information found in FIDE’s public database may be visible to the administrators of the Clubs or Organisations you join via the platform. This data processing enables membership listings, and the composition of clubs or teams to be clear to those administrators, and is processed in accordance with our legitimate interests, per Article 6(1)(f) of the GDPR.",
  "p4_7": "Support: when you ask us to resolve a technical problem or other administrative issue, we collect a certain amount of information, such as your name, email address, username, and the circumstances of the issue. We may also need to access your account to see a problem or take action for you. This additional data is kept until the problem is resolved, and then for the retention period whilst the account is active or to manage possible disputes. This processing is carried out under Article 6(1)(f), our legitimate interests to support our users.",
  "p4_8": "Site Improvement: we use data relating to our website delivery to evolve our services in such a way that they are more effective for our users. We may keep some anonymised data regardless, such as navigation, uptake, engagement, and activity of our services. We only keep this data for as long as necessary for the analysis allowing us to identify areas for improving our services. This processing is justified under our legitimate interests, per Article 6(1)(f), allowing us to develop and improve our services, and grow the sport of chess.",
  "p4_9": "Litigation Mitigation: we will keep any data where conservation of such data appears necessary to prevent a dispute or to manage and mitigate any litigation or pre-litigation. This retention is justified by our legitimate interests in protecting our rights and interests, in accordance with Article 6(1)(f). This data will be retained for the legally applicable limitation period.",
  "title5": "Information Uses, Sharing & Disclosure",
  "p5_1": "We respect your privacy. We will not disclose your name, email address, or other personal information to third parties without your consent, except as specified in this privacy notice.",
  "p5_2": "When you access or use our website, we collect, use, share, and otherwise process your personal information for the purposes described in this notice. We rely on a number of legal bases to use your information in these ways. These legal bases include where:",
  "p5_l_1": "You have consented to the processing, which you can revoke at any time;",
  "p5_l_2": "It is necessary to comply with a legal or regulatory obligation, a court order, or to exercise or defend any impending or asserted legal claims;",
  "p5_l_3": "It is necessary for the purposes of our or a third party’s legitimate interests, such as those of partner clubs, organisations, and federations;",
  "p5_l_4": "You have expressly made the information public;",
  "p5_l_5": "It is necessary in the public interest, such as to prevent crime;",
  "p5_l_6": "If it is necessary to protect your vital interests or those of others;",
  "p5_l_7": "If it is necessary to perform the contractual obligations in our Terms of Service and in order to provide our services to you or our partners.",
  "p5_3": "We principally rely on consent (which can be withdrawn at any time) under Article 6(1)(a) of GDPR when a user registers for an account with us. Some of our processing is also conducted under the basis of legitimate interests, under Article 6(1)(f) of GDPR, primarily to provide and improve our services, providing non-marketing emails (such as password recovery), keeping our services safe and secure, and other legal and regulatory purposes. ",
  "title6": "Data access",
  "p6_1": "Your data is hosted by a professional service provider, based in France. Our primary server is based in France, and the back up server is based in Lithuania. All servers are therefore located in the European Union and there are no transfers of personal information outside of the European Union. All providers are located in the European Union, who are well-known and market-leading service providers  who are certified GDPR compliant.",
  "title7": "Your rights",
  "p7_1": "Under data protection law, you have rights we need to make you aware of. The rights available to you depend on our reason for processing your information.",
  "p7_l_1": "Right to access - you have the right to ask us for copies of your personal information. This right always applies. There are some exemptions, which means you may not always receive the information we process.",
  "p7_l_2": "Right to rectification - you have the right to ask us to rectify information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete. This right always applies.",
  "p7_l_3": "Right to erasure - you have the right to ask us to erase your personal information in certain circumstances.",
  "p7_l_4": "Right to restrict processing - you have the right to ask us to restrict the processing of your information in certain circumstances.",
  "p7_l_5": "Right to object to processing - you have the right to object to processing if we are able to process your information because the process forms part of our public tasks, or is in our legitimate interests.",
  "p7_l_6": "Right to data portability - you have the right to ask that we transfer the information you have given us from one organisation to another, or give it to you. This right only applies if we are processing information based on your consent.",
  "p7_2": "You are not required to pay any charge for exercising your rights. We have one month to respond to you. These rights only extend to EEA residents; we may require further verification to be able to verify and complete your requests. ",
  "title8": "Complaints",
  "p8_1": "We work to high standards when it comes to processing your personal information. If you have queries or concerns, please contact us at hei@spillsjakk.no and we’ll respond.",
  "p8_2": "If you remain dissatisfied, you can make a complaint about the way we process your personal information to your national data protection regulator, or to the competent authority for data protection in Norway, namely Datatilsynet, via their website datatilsnyet.no or by post to the following address: Datatilsnyet, PO Box 458 Sentrum, NO-0105 Oslo.",
  "p8_3": "This right can be exercised at any time and does not incur any costs for you, apart from the costs of sending the postal mail (if applicable), and any possible costs for representation if you choose to seek assistance by a third party. "
}